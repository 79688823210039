<template>
  <div class="flex flex-col items-center w-full">
    <div class="w-full max-w-5xl p-6 mb-10 text-left text-white shadow rounded-xl bg-secondary">
      <div class="flex flex-col md:flex-row">
        <div class="flex flex-col" style="width: 70rem">
          <img src="https://app.virtualhallway.ca/images/DrGoldenberg.JPG" class="object-cover w-24 h-24 mb-4 mr-6 rounded-xl md:mb-0" />
          <div class="flex flex-col mb-4 mr-8 md:mb-0">
            <h2 class="mb-2 text-xl font-bold">Speaker</h2>
            <div class="flex flex-row items-center">
              <p class="mr-2 text-md">Dr. Ronald Goldenberg</p>
            </div>
            <p class="text-md">Endocrinology</p>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-row items-center">
            <p class="mr-2 text-sm">Dr. Ronald Goldenberg is a consultant endocrinologist emeritus affiliated with LMC Diabetes and Endocrinology in Vaughan, Ontario. He completed his residency in Internal Medicine in 1987 and his fellowship in Endocrinology and Metabolism in 1989, both at the University of Toronto. Major areas of interest include clinical care of diabetes, obesity, dyslipidemia, and thyroid disorders. He has been an investigator in a wide array of studies, a guideline leader and earned numerous awards for his work to help those suffering from metabolic disorders. He has been actively involved in Continuing Medical Education for over 3 decades, with a strong focus on translating evidence-based medicine into practical patient care.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full max-w-5xl p-6 mb-10 text-left text-white shadow rounded-xl bg-secondary">
      <div class="flex flex-col md:flex-row">
        <div class="flex flex-col" style="width: 70rem">
          <img src="https://app.virtualhallway.ca/images/DrBaselBari.jpeg" class="object-cover w-24 h-24 mb-4 mr-6 rounded-xl md:mb-0" />
          <div class="flex flex-col mb-4 mr-8 md:mb-0">
            <h2 class="mb-2 text-xl font-bold">Speaker</h2>
            <div class="flex flex-row items-center">
              <p class="mr-2 text-md">Dr. Basel Bari</p>
            </div>
            <p class="text-md">Family Medicine</p>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-row items-center">
            <p class="mr-2 text-sm">Dr. Basel Bari is a Family Physician and Medical Director at Markham HealthPlex Medical Centre with a practice focused on diabetes, cardiology, and chronic disease management since 1995. His medical training included an MD from the University of Toronto, an internship residency at Sunnybrook Health Sciences Centre, and working as a staff in Sunnybrook’s Department of Cardiology. He has contributed to continuing medical education (CME) in various capacities, especially in fields like diabetes, asthma, COPD, overactive bladder and heart failure. Dr. Bari is actively involved in clinical trials in coronary artery disease and heart failure, and he also serves on a variety of family practice advisory boards for diabetes management.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>
<style></style>
