<template>
  <form method="post" @submit.prevent="submit">
    <div class="flex flex-col items-center">
      <h1 class="mt-2 text-xl font-bold text-blue">Contact Support Form</h1>
      <div class="seperator"></div>
      <div class="flex flex-row items-center justify-center info-paragraph--gray">
        <i class="pr-2 pi pi-exclamation-triangle" style="fontsize: 1rem" />
        <p class="text-xs text-center">Do not include any patient information in your request.</p>
      </div>
    </div>
    <div>
      <label class="auth-input" for="closeInput">How would you prefer we contact you? <span class="error"></span></label>
      <Dropdown v-model="contactMethod" :options="contactMethodOptions" optionLabel="name" optionValue="value" id="contactMethodDropdown" class="auth-input" required />
    </div>
    <div class="pt-2 mb-2">
      <label class="auth-input">What do you need help with?<span class="error"></span> </label>
      <Textarea v-model="contactMessage" class="auth-input" :autoResize="true" rows="5" cols="30" required maxLength="65535" />
    </div>
    <div class="flex pt-2 flex-start">
      <span class="error"></span>
      <Checkbox v-model="confirmNoPatientInfo" :binary="true" name="confirmNoPatientInfo" id="confirmNoPatientInfo" required :class="[{ 'p-invalid': confirmNoPatientInfoError }]" />
      <label for="confirmNoPatientInfo" :class="[{ 'text-red-500': confirmNoPatientInfoError }, 'text-left', 'ml-2', 'text-xs']"> I confirm that there is no patient data included in the above form.<br />{{ confirmNoPatientInfoErrorMessage }}</label>
    </div>
    <div class="flex justify-end pt-5">
      <Button id="contactSupportSubmit" type="submit" name="submitButton" @click="validForm"> Submit </Button>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';

export default {
  components: {
    Button,
    Checkbox,
    Textarea,
    Dropdown,
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  data() {
    return {
      contactMethodOptions: [
        { name: 'Email', value: 'Email' },
        { name: 'Phone', value: 'Phone' },
      ],
      contactMessage: '',
      contactMethod: 'Email',
      confirmNoPatientInfo: false,
      confirmNoPatientInfoError: false,
      confirmNoPatientInfoErrorMessage: '',
      currentRoute: window.location.href,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      user_agent: navigator.userAgent,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions(['contactSupport']),
    submit() {
      if (this.confirmNoPatientInfo) {
        this.$toast.removeAllGroups();
        this.isLoading = true;
        this.contactSupport({
          user_id: this.loggedInUser.id,
          contactMessage: this.contactMessage,
          confirmNoPatientInfo: this.confirmNoPatientInfo,
          contactMethod: this.contactMethod,
          currentRoute: this.currentRoute,
          role: this.loggedInUser.role,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          user_agent: this.user_agent,
          org_units: this.loggedInUser.org_units.length > 0 ? this.loggedInUser.org_units[0].name : null,
        })
          .then(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'success',
              summary: 'Ticket Submitted',
              detail: 'Your support ticket has been sent. We will contact you shortly.',
              life: 5000,
            });
            this.contactMessage = '';
            this.confirmNoPatientInfo = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error processing your request, please try again later or contact support@virtualhallway.ca.',
              life: 10000,
            });
          });
      }
    },
    validForm() {
      this.confirmNoPatientInfoError = false;
      this.error = '';
      this.sucess = '';
      this.confirmNoPatientInfoErrorMessage = '';
      if (!this.confirmNoPatientInfo) {
        this.confirmNoPatientInfoError = true;
        this.confirmNoPatientInfoErrorMessage = 'Please confirm there is no patient information';
      }
    },
  },
};
</script>

<style></style>
