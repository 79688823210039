<template>
  <div class="flex flex-row justify-between w-full text-left">
    <div class="flex-col justify-start flex-">
      <h1 class="mb-6 text-2xl font-bold">Archived lectures</h1>
      <p class="mb-4 -mt-4 text-sm">Select a lecture below to watch the recording. Archived lectures do not provide CME credits.</p>
      <div class="mb-8 c-divider" style="margin-left: 0"></div>
    </div>
  </div>

  <div class="grid grid-cols-1 gap-6 mb-6 lecture-grid justify-items-center md:grid-cols-2 lg:grid-cols-3">
    <div v-for="lecture in archivedLectures" :key="lecture.id" class="flex flex-col justify-between w-full bg-white shadow-md cursor-pointer animated fade-in-up animated-delay-3 rounded-xl" @click="goToLectureId(lecture.id)">
      <div class="relative h-64 overflow-hidden v-r-top video-background" :style="{ backgroundImage: `url(${lecture.lecture_image_location})` }"></div>
      <div class="flex flex-col justify-between p-4 text-left text-gray-600 rounded-b-xl grow">
        <div class="pb-2 mb-2 font-bold border-b text-md">
          <h1>{{ lecture.lecture_title }}</h1>
        </div>
        <div class="flex flex-row items-center justify-between w-full border-round">
          <div class="flex flex-col">
            <h3 class="mb-1 text-sm font-bold">Dr. {{ lecture.presenter_name }} • {{ lecture.presenter_specialty }}</h3>
          </div>
        </div>
        <Button class="p-button-outlined get-started-button w-full p-button-sm !mt-3" style="display: block" icon="pi pi-play-circle" label="Watch" />
      </div>
    </div>
    <div class="flex flex-col justify-between w-full bg-white shadow-md cursor-pointer animated fade-in-up animated-delay-3 rounded-xl" @click="archivedLectureCardOnClick({ display: 'displayBasic6', url: 'https://vimeo.com/715239039' })">
      <div class="relative v-r-top">
        <img :src="getURL() + 'images/Dr-Jacob-Cookey.jpeg'" class="object-cover w-full h-64 rounded-t-xl" alt="Dr. Fraser" />

        <Dialog :draggable="false" v-model:visible="displayBasic6" :modal="true" :style="getDialogWidth">
          <template #header>
            <h2 class="text-sm text-gray-600">Benzos and Z-Drugs: When to use them, when to avoid them</h2>
          </template>
          <div class="flex justify-center py-2">
            <vimeo-player ref="player" video-id="715239039" @ready="onReady" />
          </div>
        </Dialog>
      </div>
      <div class="flex flex-col justify-between p-4 text-left text-gray-600 rounded-b-xl grow">
        <h2 class="pb-2 mb-2 font-bold border-b text-md">Benzos and Z-Drugs: When to use them, when to avoid them</h2>
        <h3 class="text-sm font-bold">Dr. Jacob Cookey • Psychiatry</h3>
        <Button class="p-button-outlined get-started-button w-full p-button-sm !mt-3" style="display: block" icon="pi pi-play-circle" label="Watch" />
      </div>
    </div>
    <div class="flex flex-col justify-between w-full bg-white shadow-md cursor-pointer animated fade-in-up animated-delay-3 rounded-xl" @click="archivedLectureCardOnClick({ display: 'displayBasic5', url: 'https://vimeo.com/696515040' })">
      <div class="relative v-r-top">
        <img :src="getURL() + 'images/Dr-Fraser-Lecture.png'" class="object-cover w-full h-64 rounded-t-xl" alt="Dr. Fraser" />

        <Dialog :draggable="false" v-model:visible="displayBasic5" :modal="true" :style="getDialogWidth">
          <template #header>
            <h2 class="text-sm text-gray-600">Treatment of Addictive Disorders</h2>
          </template>
          <div class="flex justify-center py-2">
            <vimeo-player ref="player" video-id="696515040" @ready="onReady" />
          </div>
        </Dialog>
      </div>
      <div class="flex flex-col justify-between p-4 text-left text-gray-600 rounded-b-xl grow">
        <h2 class="pb-2 mb-2 font-bold border-b text-md">Treatment of Addictive Disorders</h2>
        <h3 class="text-sm font-bold">Dr. Ronald Fraser • Psychiatry</h3>
        <Button class="get-started-button w-full p-button-sm !mt-3 p-button-outlined" label="Watch" icon="pi pi-play-circle" style="display: block" />
      </div>
    </div>
    <div class="flex flex-col justify-between w-full bg-white shadow-md cursor-pointer animated fade-in-up animated-delay-3 rounded-xl" @click="archivedLectureCardOnClick({ display: 'displayBasic4', url: 'https://vimeo.com/671208313' })">
      <div class="relative v-r-top">
        <img :src="getURL() + 'images/RockWood.jpg'" class="object-cover w-full h-64 rounded-t-xl" alt="Dr. Rockwood" />

        <Dialog :draggable="false" v-model:visible="displayBasic4" :modal="true" :style="getDialogWidth">
          <template #header>
            <h2 class="text-sm text-gray-600">Helping People aged 75+ Optimize their Health</h2>
          </template>
          <div class="flex justify-center py-2">
            <vimeo-player ref="player" video-id="671208313" @ready="onReady" />
          </div>
        </Dialog>
      </div>
      <div class="flex flex-col justify-between p-4 text-left text-gray-600 rounded-b-xl grow">
        <h2 class="pb-2 mb-2 font-bold border-b text-md">Helping People aged 75+ Optimize their Health</h2>
        <h3 class="text-sm font-bold">Dr. Rockwood • Geriatric Medicine</h3>
        <Button class="p-button-outlined get-started-button w-full p-button-sm !mt-3" style="display: block" icon="pi pi-play-circle" label="Watch" />
      </div>
    </div>
    <div class="flex flex-col justify-between w-full bg-white shadow-md cursor-pointer animated fade-in-up animated-delay-3 rounded-xl" @click="archivedLectureCardOnClick({ display: 'displayBasic1', url: 'https://vimeo.com/655381820' })">
      <div class="relative v-r-top">
        <img :src="getURL() + 'images/Tennankore.jpg'" class="object-cover w-full h-64 rounded-t-xl" alt="Dr. Tennankore" />

        <Dialog :draggable="false" v-model:visible="displayBasic1" :modal="true" :style="getDialogWidth">
          <template #header>
            <h2 class="text-sm text-gray-600">Planning and Preparation for Kidney Replacement Therapy</h2>
          </template>
          <div class="flex justify-center py-2">
            <vimeo-player ref="player" video-id="655381820" @ready="onReady" />
          </div>
        </Dialog>
      </div>
      <div class="flex flex-col justify-between p-4 text-left text-gray-600 rounded-b-xl grow">
        <h2 class="pb-2 mb-2 font-bold border-b text-md">Planning and Preparation for Kidney Replacement Therapy</h2>
        <h3 class="text-sm font-bold">Dr. Karthik Tennankore • Nephrology</h3>
        <Button class="p-button-outlined get-started-button w-full p-button-sm !mt-3" style="display: block" icon="pi pi-play-circle" label="Watch" />
      </div>
    </div>
    <div class="flex flex-col justify-between w-full bg-white shadow-md cursor-pointer animated fade-in-up animated-delay-3 rounded-xl" @click="archivedLectureCardOnClick({ display: 'displayBasic2', url: 'https://vimeo.com/648749419' })">
      <div class="relative v-r-top">
        <img :src="getURL() + 'images/Marval.jpg'" class="object-cover w-full h-64 rounded-t-xl" alt="Dr. Marval" />

        <Dialog :draggable="false" v-model:visible="displayBasic2" :modal="true" :style="getDialogWidth">
          <template #header>
            <h2 class="text-sm text-gray-600">Insomnia: Presentation, Assessment, and Management</h2>
          </template>
          <div class="flex justify-center py-2">
            <vimeo-player ref="player" video-id="648749419" @ready="onReady" />
          </div>
          <div class="flex flex-col justify-center max-w-xl p-1 mx-auto">
            <p class="info-paragraph--gray">If you would like the Insomnia Treatment Patient Handout Dr. Heizer Marval-Ospino provided click the download button.</p>
            <div class="flex justify-end">
              <a :href="getURL() + 'images/Insomnia_Treatment_Patient_Handout_HM.pdf'" target="_blank" class="ml-auto footer-link"> <Button icon="pi pi-download" label="Download" /> </a>
            </div>
          </div>
        </Dialog>
      </div>
      <div class="flex flex-col justify-between p-4 text-left text-gray-600 rounded-b-xl grow">
        <h2 class="pb-2 mb-2 font-bold border-b text-md">Insomnia: Presentation, Assessment, and Management</h2>
        <h3 class="text-sm font-bold">Dr. Heizer Marval-Ospino • Sleep Medicine</h3>
        <Button class="p-button-outlined get-started-button w-full p-button-sm !mt-3" style="display: block" icon="pi pi-play-circle" label="Watch" />
      </div>
    </div>
    <div class="flex flex-col justify-between w-full bg-white shadow-md cursor-pointer animated fade-in-up animated-delay-3 rounded-xl" @click="archivedLectureCardOnClick({ display: 'displayBasic3', url: 'https://vimeo.com/641638713' })">
      <div class="relative v-r-top">
        <img :src="getURL() + 'images/VanZoost.png'" class="object-cover w-full h-64 rounded-t-xl" alt="Dr. Van Zoost" />

        <Dialog :draggable="false" v-model:visible="displayBasic3" :modal="true" :style="getDialogWidth">
          <template #header>
            <h2 class="text-sm text-gray-600">Iron Deficiency Anemia</h2>
          </template>
          <div class="flex justify-center py-2">
            <vimeo-player ref="player" video-id="641638713" @ready="onReady" />
          </div>
        </Dialog>
      </div>
      <div class="flex flex-col justify-between p-4 text-left text-gray-600 rounded-b-xl grow">
        <h2 class="pb-2 mb-2 font-bold border-b text-md">Iron Deficiency Anemia</h2>
        <h3 class="text-sm font-bold">Dr. Colin Van Zoost • Internal Medicine</h3>
        <Button class="p-button-outlined get-started-button w-full p-button-sm !mt-3" style="display: block" icon="pi pi-play-circle" label="Watch" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['selectedLecture', 'archivedLectures'],
  data() {
    return {
      displayBasic1: false,
      displayBasic2: false,
      displayBasic3: false,
      displayBasic4: false,
      displayBasic5: false,
      displayBasic6: false,
    };
  },
  methods: {
    getDialogWidth() {
      return window.innerWidth > 1200 ? { width: '70vw' } : { width: '90vw' };
    },
    archivedLectureCardOnClick(event) {
      if (window.innerWidth > 700) {
        this[event.display] = !this[event.display];
      } else {
        window.open(event.url, '_blank');
      }
    },
    goToLectureId(id) {
      this.$router.push('/lecture/' + id);
    },
  },
};
</script>
<style></style>
