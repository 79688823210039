<template>
  <template v-if="isLoading">
    <div class="flex flex-row items-center justify-center h-24">
      <i class="pi pi-spin pi-spinner" style="font-size: 1.5rem; color: #5e76ff"></i>
    </div>
  </template>
  <template v-else>
    <div v-if="notifications.length < 1" class="flex flex-col items-center justify-center w-full p-6 mb-4 bg-white rounded-xl">
      <i class="my-4 pi pi-calendar-times text-grey-400" style="font-size: 2rem"></i>
      <div v-if="actingUser.specialist_preference?.call_asap_optin == false" class="flex flex-col items-center justify-center w-full">
        <h3 class="mb-2 fc-toolbar-title">No New Notifications</h3>
        <p class="mx-5 mt-2 mb-4 text-sm tip-title">Did you know that enabling <strong>OnDemand Consults</strong> allow you to call the referring practitioner any time before the scheduled date? Click below to learn more and enable.</p>
        <Button icon="fas fa-bolt" label="OnDemand Consults" @click="goToPreference()" />
      </div>

      <div v-else class="flex flex-col items-center justify-center w-full">
        <h3 class="mb-6 fc-toolbar-title">No New Notifications</h3>
        <Button v-if="isSpecialist(actingUser)" icon="pi pi-calendar" label="Add Availability" @click="goToAvailability()" />
      </div>
    </div>
    <div v-for="notification in notifications.slice(0, 7)" :key="notification.id">
      <div class="relative w-full p-3 bg-white border-b border-gray-200 border-solid cursor-pointer hover:bg-gray-50">
        <div class="flex flex-row items-center justify-between w-full">
          <div class="flex flex-row items-center justify-center my-1">
            <template v-if="notification.status != 'Unread'">
              <div v-tooltip="'Mark as Unread'" @click="flipNotificationStatus(notification)" class="flex items-center justify-center rounded align-center hover:bg-gray-200" style="width: 2rem; height: 2rem">
                <i class="text-xs text-left text-gray-400 pi pi-envelope"></i>
              </div>
            </template>
            <template v-else>
              <div v-tooltip="'Mark as Read'" @click="flipNotificationStatus(notification)" class="flex items-center justify-center rounded align-center hover:bg-gray-300" style="width: 2rem; height: 2rem">
                <i class="text-xs text-left text-gray-400 pi pi-envelope"></i>
              </div>
            </template>
          </div>

          <div class="flex flex-col w-full ml-2 text-left" @click="selectNotification(notification)">
            <div class="flex flex-row items-start justify-between w-full">
              <div class="flex flex-row items-center text-xs font-semibold text-gray-500 uppercase">
                {{ notification.title }}
                <div v-if="notification.status == 'Active' || notification.status == 'Unread'" style="height: 8px; width: 8px; background-color: #ff6a78" class="ml-2 rounded-full"></div>
              </div>
              <div class="text-gray-500 post-date">
                <!-- {{ formatNotificationDate(notification.meta_data.referral_start_datetime) }} -->
                {{ formatNotificationDate(notification.created_at) }}
              </div>
            </div>
            <div class="text-gray-500" style="font-size: 0.65rem">{{ notification.details }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center mt-4">
      <Button @click="goToAllNotifications" icon="pi pi-arrow-right" iconPos="right" class="text-xs p-button-text p-button-secondary p-button-sm light-button" label="View All Notifications" />
    </div>
  </template>
</template>

<script>
import Button from 'primevue/button';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
  components: {
    Button,
  },
  props: ['referrals'],
  emits: ['toggle'],
  data() {
    return {
      id: '',
      notifications: [],
      existingNotifications: [],
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(['actingUser', 'getUserActionsByName', 'loggedInUser', 'getUserActionById', 'isSpecialist', 'isGP']),
  },
  methods: {
    ...mapActions(['getUserActions', 'updateUserAction']),
    selectNotification(notification) {
      if (notification?.meta_data.lecture_id) {
        this.$router.push('/lecture/' + notification?.meta_data.lecture_id);
      }
      if (notification?.meta_data.referral_id) {
        let query = '';
        if (notification.name === 'Thank You') {
          query = '?thankYou=true';
        }
        this.$router.push('/referral/' + notification?.meta_data.referral_id + query);
      }
      this.$emit('toggle');
    },
    goToAllNotifications() {
      this.$router.push('/allnotifications/');
      this.$emit('toggle');
    },
    goToAvailability() {
      this.$router.push('/schedules/');
      this.$emit('toggle');
    },
    goToPreference() {
      this.$router.push('/preferences/');
      this.$emit('toggle');
    },
    flipNotificationStatus(notification) {
      if (notification.status == 'Unread') {
        notification.status = 'Read';
      } else notification.status = 'Unread';
      this.updateUserAction(notification);
    },
  },
  created() {
    this.getUserActions({ set_active_notifications_as_read: true })
      .then((response) => {
        this.notifications = response.data.data;
        this.notifications = this.notifications.filter((n) => {
          if (n.type == 'app_notification' && n?.meta_data.referral_start_datetime) {
            n.meta_data.referral_start_datetime = moment.utc(n.meta_data.referral_start_datetime).toDate();
          }
          return n.type == 'app_notification';
        });
        this.isLoading = false;
      })
      .catch(() => {
        this.showGenericErrorToast();
        this.isLoading = false;
      });
  },
  unmounted() {
    let loggedInUser = this.loggedInUser;
    let userActions = this.loggedInUser.actions;
    userActions.forEach((n, index) => {
      if (n.type == 'app_notification' && n.status == 'Active') {
        n.status = 'Read';
      }
      userActions[index] = n;
    });
    loggedInUser.actions = userActions;
    this.$store.commit('updateLoggedInUser', loggedInUser);
  },
};
</script>

<style scoped>
.no-consult-card {
  height: 4rem;
}

.light-button {
  color: #465875 !important;
  font-weight: 500 !important;
  font-size: 0.8rem !important;
}
</style>
