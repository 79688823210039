<template>
  <div class="main-inner-wrap">
    <div class="flex items-center justify-center flex-grow">
      <div class="text-center p4">
        <i class="mb-4 pi pi-ban" style="font-size: 4rem"></i>
        <h1 class="mb-4 text-4xl">Page Not Found</h1>
        <template v-if="isLoggedIn">
          <router-link to="/contactsupport" class="p-button" exact-path>Contact Support</router-link>
        </template>
        <template v-else>
          <p class="mb-4 w-80">Try logging in, or contact us for support.</p>
          <a href="https://virtualhallway.ca/contact" class="p-button">Contact Support</a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
};
</script>
