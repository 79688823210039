<template class="h-full">
  <div class="content-center h-screen grid">
    <ProgressSpinner />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProgressSpinner from 'primevue/progressspinner';
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  components: {
    ProgressSpinner,
  },
  created() {
    let params = this.$route.params;
    params.type = 'cortico_referral_request';
    if (!this.loggedInUser) {
      this.$router.push({ name: 'Login', params: params, query: { redirect: '/makereferral' } });
    } else {
      this.storeExternalReferralRequestCode(params.requestCode);
      this.$router.push({ name: 'MakeReferral', params: params });
    }
  },
  methods: {
    ...mapActions(['storeExternalReferralRequestCode']),
  },
};
</script>

<style></style>
