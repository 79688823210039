<template>
  <Sidebar v-model:visible="sideBarVisible" position="right" class="flex flex-col public-sidebar-container">
    <ul>
      <li class="flex flex-col public-sidebar-list-item">
        <a href="https://virtualhallway.ca/" class="public-sidebar-list-sub-heading">Home</a>
      </li>
      <li class="flex flex-col public-sidebar-list-item" @click="howItWorksOnClick">
        <div class="public-sidebar-list-sub-heading">
          Who We Help
          <i :class="['pi', 'pi-plus', 'public-nav-icon', { active: howItWorksActive }]"></i>
        </div>
      </li>
      <ul v-if="howItWorksActive">
        <li class="flex flex-col public-sidebar-list-item">
          <a href="https://virtualhallway.ca/primary-care" class="public-sidebar-list-sub-heading">Family Physicians</a>
        </li>
        <li class="flex flex-col public-sidebar-list-item">
          <a href="https://virtualhallway.ca/nurse-practitioners" class="public-sidebar-list-sub-heading">Nurse Practitioners</a>
        </li>
        <li class="flex flex-col public-sidebar-list-item">
          <a href="https://virtualhallway.ca/specialists" class="public-sidebar-list-sub-heading">Specialists</a>
        </li>
        <li class="flex flex-col public-sidebar-list-item">
          <a href="https://virtualhallway.ca/innovation-leaders" class="public-sidebar-list-sub-heading">Innovation Leaders</a>
        </li>
      </ul>
      <li class="flex flex-col public-sidebar-list-item" @click="aboutOnClick">
        <div class="public-sidebar-list-sub-heading">
          About
          <i :class="['pi', 'pi-plus', 'public-nav-icon', { active: aboutActive }]"></i>
        </div>
      </li>
      <ul v-if="aboutActive">
        <li class="flex flex-col public-sidebar-list-item">
          <a href="https://virtualhallway.ca/about" class="public-sidebar-list-sub-heading">About Us</a>
        </li>
        <li class="flex flex-col public-sidebar-list-item">
          <a href="https://virtualhallway.ca/faq" class="public-sidebar-list-sub-heading">FAQ</a>
        </li>
        <li class="flex flex-col public-sidebar-list-item">
          <a href="https://virtualhallway.ca/blog" class="public-sidebar-list-sub-heading">Blog</a>
        </li>
        <li class="flex flex-col public-sidebar-list-item">
          <a href="https://virtualhallway.ca/news" class="public-sidebar-list-sub-heading">News</a>
        </li>
        <li class="flex flex-col public-sidebar-list-item">
          <a href="https://virtualhallway.ca/contact" class="public-sidebar-list-sub-heading">Contact us</a>
        </li>
      </ul>
      <li class="flex flex-col public-sidebar-list-item">
        <router-link @click="sideBarVisible = false" to="/lectures" class="public-sidebar-list-sub-heading">Watch CME Lectures <i class="ml-2 pi pi-video public-nav-icon"></i></router-link>
      </li>
      <li class="flex flex-col public-sidebar-list-item" v-if="displayPoems()">
        <router-link @click="sideBarVisible = false" to="/poems" class="public-sidebar-list-sub-heading">View CME POEMs <i class="ml-2 pi pi-book public-nav-icon"></i></router-link>
      </li>
      <li class="flex flex-col public-sidebar-list-item">
        <router-link @click="sideBarVisible = false" to="/login" class="public-sidebar-list-sub-heading">Login <i class="ml-2 pi pi-unlock public-nav-icon"></i></router-link>
      </li>
      <li class="flex flex-col public-sidebar-list-item">
        <router-link @click="sideBarVisible = false" to="/registration" class="public-sidebar-list-sub-heading">Sign Up <i class="ml-2 pi pi-user-plus public-nav-icon"></i></router-link>
      </li>
    </ul>
  </Sidebar>
</template>

<script>
import Sidebar from 'primevue/sidebar';

export default {
  props: ['openSideBar'],
  components: {
    Sidebar,
  },
  data() {
    return {
      sideBarVisible: false,
      howItWorksActive: false,
      aboutActive: false,
      resourcesActive: false,
    };
  },
  methods: {
    howItWorksOnClick() {
      if (this.aboutActive) {
        this.aboutActive = false;
        this.howItWorksActive = !this.howItWorksActive;
      } else {
        this.howItWorksActive = !this.howItWorksActive;
      }
    },
    aboutOnClick() {
      if (this.howItWorksActive) {
        this.howItWorksActive = false;
        this.aboutActive = !this.aboutActive;
      } else {
        this.aboutActive = !this.aboutActive;
      }
    },
    resourcesOnClick() {
      if (this.howItWorksActive) {
        this.howItWorksActive = false;
        this.resourcesActive = !this.resourcesActive;
      } else {
        this.resourcesActive = !this.resourcesActive;
      }
    },
  },
  watch: {
    openSideBar() {
      if (this.openSideBar) {
        this.sideBarVisible = true;
      }
    },
    sideBarVisible() {
      this.$emit('togglePublicSideBar', this.sideBarVisible);
    },
  },
};
</script>

<style scoped>
.public-sidebar-container {
  max-width: 100%;
}
.public-sidebar-item,
.public-sidebar-list-item {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #eff4fb;
  font-size: 22px;
  cursor: pointer;
  width: 100%;
}
.public-sidebar-list-item {
  list-style-type: none;
}
.public-sidebar-item {
  align-items: center;
  justify-content: flex-start;
  font-family: 'Poppins';
}
.public-nav-icon {
  font-size: 1.4rem;
}

.public-sidebar-sub-heading,
.public-sidebar-list-sub-heading {
  padding-bottom: 15px;
  padding-top: 15px;
  color: var(--vh-black);
}
.public-sidebar-list-sub-heading,
.public-sidebar-list-sub-heading a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins';
}
.public-sidebar-list-sub-heading i.pi-plus {
  transform: rotate(0deg);
  transition-duration: 0.2s;
}
.public-sidebar-list-sub-heading i.pi-plus.active {
  transform: rotate(135deg);
  transition-duration: 0.2s;
}
</style>
