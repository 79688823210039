<template>
  <div v-show="show" v-if="!isMobile" aria-describedby="ios-app-prompt-description" aria-labelledby="ios-app-prompt-title" role="dialog" class="visible iosAppPrompt legacy ios-container">
    <div class="iosAppPromptHeader ios-header">
      <div class="flex flex-row">
        <img src="@/assets/icon.png" alt="" class="h-14 rounded-xl" />
        <div class="flex flex-col mt-2 ml-3">
          <p id="ios-app-prompt-title" class="iosAppPromptTitle ios-title">Download the App</p>
          <p id="ios-app-prompt-subheading" class="iosAppPromptCopy ios-title">Virtual Hallway Consults</p>
        </div>
      </div>
      <button class="iosAppPromptCancel ios-cancel" @click="closePrompt()">Close</button>
    </div>
    <div class="iosAppPromptBody ios-body">
      <div class="iosAppPromptDescription ios-description"><p id="ios-app-prompt-description" class="iosAppPromptCopy ios-description-copy">Experience the convenience of Virtual Hallway on your mobile device. Secure consults, seamless documentation, and private calling - all in your pocket. Click below to download from the App Store.</p></div>
    </div>
    <div class="flex justify-center my-2 iosAppPromptInstruction">
      <button class="iosAppPromptButton" @click="closePromptAndGoToAppStore()">Download from App Store</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['user'],
  data() {
    return {
      isLoading: false,
      show: true,
    };
  },
  computed: {},
  methods: {
    openPrompt() {
      this.show = true;
    },
    closePrompt() {
      this.show = false;
    },
    closePromptAndGoToAppStore() {
      this.show = false;
      window.open('https://virtualhallway.ca/appstore');
    },
  },
};
</script>
<style lang="scss">
$overlay-color-legacy: rgba(0, 0, 0, 0.8);
$overlay-color-modern-light: rgba(10, 10, 10, 0.5);
$overlay-color-modern-dark: rgba(10, 10, 10, 0.5);

$bg-color-legacy: rgba(250, 250, 250, 0.8);
$bg-color-modern-light: rgba(255, 255, 255, 0.6);
$bg-color-modern-dark: rgba(19, 19, 19, 0.85);

$border-color-legacy: rgba(0, 0, 0, 0.1);
$border-color-modern-light: rgba(60, 60, 67, 0.29);
$border-color-modern-dark: rgba(140, 140, 140, 0.7);

$font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

$title-color-legacy: rgb(51, 51, 51);
$title-color-modern-light: rgba(0, 0, 0, 1);
$title-color-modern-dark: rgba(255, 255, 255, 1);

$font-color-legacy: rgb(123, 123, 122);
$font-color-modern-light: rgba(60, 60, 67, 0.6);
$font-color-modern-dark: rgba(235, 235, 245, 0.6);

$blue-color-legacy: rgb(45, 124, 246);
$blue-color-modern-light: rgba(0, 85, 179, 1);
$blue-color-modern-dark: rgba(9, 132, 255, 1);

.noScroll {
  overflow: hidden;
}

#ios-app-prompt-subheading {
  margin-top: 5px;
  font-size: 14px;
}

.iosAppPromptOverlay {
  background-color: $overlay-color-legacy;
  left: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.2s ease-in;
  width: 100vw;
  z-index: 999999;

  &.visible {
    opacity: 1;
    display: block;
  }

  &.hidden {
    pointer-events: none;
    touch-action: none;
  }

  &.modern {
    @media (prefers-color-scheme: dark) {
      & {
        background: $overlay-color-modern-dark;
        color: $font-color-modern-dark;
      }
    }
  }
}

.iosAppPromptButton {
  border-radius: 50px;
  color: white;
  padding: 10px 30px;
  background-color: $blue-color-modern-dark;
  font-family: $font-family;
  font-size: 14px;
}

.iosAppPrompt {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: $bg-color-modern-dark;
  border-radius: 10px;
  bottom: 0;
  color: black;
  filter: brightness(1.1);
  left: 0;
  margin: 0 8px 10px;
  overflow: hidden;
  position: fixed;
  transform: translateY(calc(100% + 10px));
  transition: transform 0.4s cubic-bezier(0.4, 0.24, 0.3, 1);
  width: calc(100vw - 16px);
  z-index: 999999;
  text-align: left;

  &.visible {
    transform: translateY(0);
    display: block;
  }

  &.hidden {
    pointer-events: none;
    touch-action: none;
  }

  &.modern {
    background: $bg-color-modern-light;
    filter: brightness(1.6);

    @media (prefers-color-scheme: dark) {
      & {
        background: $bg-color-modern-dark;
        filter: brightness(1.1);
      }
    }
  }
}

.iosAppPromptHeader {
  align-items: center;
  border-bottom: 1px solid $border-color-modern-dark;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-width: 0.5px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 13px 16px;

  .modern & {
    border-color: $border-color-modern-light;

    @media (prefers-color-scheme: dark) {
      & {
        border-color: $border-color-modern-dark;
      }
    }
  }

  .iosAppPromptTitle {
    color: $title-color-modern-dark;
    font-family: $font-family;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.125;
    margin: 0;
    padding: 0;

    .modern & {
      color: $title-color-modern-light;

      @media (prefers-color-scheme: dark) {
        & {
          color: $title-color-modern-dark;
        }
      }
    }
  }

  .iosAppPromptCancel {
    // color: $blue-color-modern-dark;
    color: #3c9eff;
    font-size: 16px;
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;

    .modern & {
      color: $blue-color-modern-light;

      @media (prefers-color-scheme: dark) {
        & {
          color: $blue-color-modern-dark;
        }
      }
    }
  }
}

.iosAppPromptBody {
  display: flex;
  width: 100%;

  .iosAppPromptDescription {
    border-bottom: 1px solid $border-color-modern-dark;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-width: 0.5px;
    color: inherit;
    // margin: 0 16px;
    padding: 16px;
    width: 100%;

    .modern & {
      border-color: $border-color-modern-light;

      @media (prefers-color-scheme: dark) {
        & {
          border-color: $border-color-modern-dark;
        }
      }
    }
  }
}

.iosAppPromptCopy {
  color: $font-color-modern-dark;
  font-family: $font-family;
  font-size: 13px;
  line-height: 17px;
  margin: 0;
  padding: 0;

  &.bold {
    font-weight: 600;
  }

  .modern & {
    color: $font-color-modern-dark;

    @media (prefers-color-scheme: dark) {
      & {
        border-color: $font-color-modern-dark;
        color: $font-color-modern-dark;
      }
    }
  }
}

.iosAppPromptInstruction {
  color: inherit;
  margin: 0 16px;
  padding: 16px;

  .iosAppPromptInstructionStep {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    text-align: left;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .iosAppPromptShareIcon,
  .iosAppPromptHomeIcon {
    flex: 0 0 auto;
    height: 30px;
    margin-right: 32px;
    width: 25px;
  }

  .iosAppPromptHomeIcon {
    color: $blue-color-modern-dark;

    .modern & {
      color: black;
      fill: black;

      @media (prefers-color-scheme: dark) {
        & {
          color: white;
          fill: white;
        }
      }
    }
  }

  .iosAppPromptShareIcon {
    // color: $title-color-modern-dark;
    // fill: $title-color-modern-dark;

    color: #3c9eff;
    fill: #3c9eff;

    .modern & {
      color: $title-color-modern-dark;
      fill: $title-color-modern-dark;

      @media (prefers-color-scheme: dark) {
        & {
          color: $title-color-modern-dark;
          fill: $title-color-modern-dark;
        }
      }
    }
  }
}
</style>
