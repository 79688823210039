<template>
  <Modal ref="registrationModal" class="mx-auto">
    <template v-slot:body>
      <div class="animated fade-in-left animated-delay-3 px-6 py-8 w-full sm:w-[576px] relative" id="registrationModal">
        <i class="pi pi-times text-gray-400 top-3 right-3 absolute cursor-pointer p-1.5" @click="$refs.registrationModal.closeModal()" style="font-size: 1rem" id="closeRegistrationModalButton"></i>
        <div class="flex flex-col items-center justify-center w-full">
          <h1 class="mb-3 text-xl font-bold">
            {{ selectedLanguage == 'Fr' ? 'Terminez votre inscription!' : 'Finish Signing Up!' }}
          </h1>
          <p class="w-full mb-5 text-sm md:px-12">
            {{ selectedLanguage == 'Fr' ? 'Créez un compte pour recevoir quotidiennement des POEM dans votre boîte de réception, parcourez les POEM, réclamez des crédits et engagez des discussions avec vos collègues.' : 'Create an account to get daily POEMs to your inbox, browse POEMs, claim credits and engage in discussions with your colleagues.' }}
          </p>
          <div class="flex flex-col w-full max-w-lg text-center text-gray-600 text-md">
            <RegistrationForm :queryProps="{ redirectFromRegistration: selectedPoem ? `/poem/${selectedPoem.id}` : '/poems' }" :prefillEmail="prefillEmail" :customHeadline="'poemsHeadline'" :selectedLanguage="selectedLanguage" />
            <div class="mt-6 text-sm text-center font-display">
              {{ selectedLanguage == 'Fr' ? 'Vous avez déjà un compte ?' : 'Already have an account? ' }}
              <span class="cursor-pointer text-primary" @click="redirectToLogin"> {{ selectedLanguage == 'Fr' ? 'Connectez-vous ici' : 'Log in here' }}</span>
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import RegistrationForm from '@/views/public/registration/registrationForm/RegistrationForm.vue';

export default {
  props: ['selectedPoem', 'prefillEmail', 'selectedLanguage'],
  inject: ['mixpanel'],
  components: { RegistrationForm },
  data() {},
  computed: {},
  methods: {
    openModal() {
      this.$refs.registrationModal.openModal();
    },
    closeModal() {
      this.$refs.registrationModal.closeModal();
    },
    redirectToLogin() {
      if (this.selectedPoem) {
        this.$router.push(`/login?redirect=poem/${this.selectedPoem.id}`);
      } else {
        this.$router.push(`/login?redirect=poems`);
      }
    },
  },
};
</script>

<style></style>
