<template>
  <div class="main-inner-wrap">
    <div class="items-start max-w-sm p-8 mx-auto text-left shadow my-9 mx-14 card rounded-xl">
      <div class="flex flex-col items-center justify-center w-full text-center">
        <i class="mb-4 pi pi-user login-form-heading-icon" style="font-size: 3rem" />
        <img class="w-60" src="@/assets/Virtual-Hallway-Logo-Horizontal.svg" alt="" />
      </div>
      <h2 class="py-10 text-xl font-medium text-center text-gray-500">Email Verification</h2>
    </div>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#5e76ff" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(['isPreboarding', 'isGP', 'isOrgUnitHead', 'isCme', 'loggedInUser']),
  },
  methods: {
    ...mapActions(['verifyEmail']),
    goToLogin() {
      this.$router.push({ path: `/login` });
    },
    handleSuccessfulLogin(user) {
      if (!user.onboarding_form.is_eula_agreed) {
        this.$router.push({ path: '/eula' });
      } else if (this.isCme(user)) {
        this.$router.push({ path: '/lectures' });
      } else if (this.isPreboarding(user)) {
        this.$router.push({ path: '/account-setup' });
      } else if (this.isGP(user)) {
        this.$router.push({ path: '/makereferral' });
      } else if (this.isOrgUnitHead(user)) {
        this.$router.push({ path: '/manageusers' });
      } else {
        this.$router.push({ path: '/home' });
      }
    },
    handleUnSuccessfulVerifyEmail() {
      this.$toast.add({
        severity: 'success',
        summary: 'Please Login',
        life: 5000,
      });
      this.$router.push({ path: '/login' });
    },
    verifyEmailSubmit() {
      this.$toast.removeAllGroups();
      this.isLoading = true;
      this.verifyEmail({
        userId: this.$route.params.userId,
        verificationCode: this.$route.params.code,
      })
        .then((response) => {
          if (response.status == 200 && response.data.message === 'Your email has been verified') {
            this.isLoading = false;
            this.$toast.add({
              severity: 'success',
              summary: 'Email Verified',
              detail: response.data.message,
              life: 5000,
            });
            this.handleSuccessfulLogin(response.data.user);
          } else {
            this.isLoading = false;
            this.handleUnSuccessfulVerifyEmail();
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.handleUnSuccessfulVerifyEmail();
        });
    },
  },
  created() {
    this.isLoading = true;
    if (this.$route.query?.isMobile === 'yes' && this.isIphoneBrowserButNotIosApp()) {
      // If the app is not installed the script will wait for 2sec and redirect to web.
      const verifyEmailSubmitFunc = this.verifyEmailSubmit;
      setTimeout(function () {
        verifyEmailSubmitFunc();
      }, 4000);

      // Try launching the app using URL schemes
      window.open(`virtualhallway:/${this.$route.path}`, '_self');
    } else {
      this.verifyEmailSubmit();
    }
  },
};
</script>

<style></style>
