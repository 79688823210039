import axios from 'axios';
import globals from '../../globals';
import moment from 'moment';

const state = {
  selectedPoem: null,
  selectedPoemComments: [],
  poemsPaginator: null,
  poemsTags: [],
};

const getters = {
  poemsPaginator: (state) => {
    return state.poemsPaginator;
  },
  poemsTags: (state) => {
    return state.poemsTags;
  },
  selectedPoem: (state) => {
    return state.selectedPoem;
  },
  selectedPoemComments: (state) => {
    return state.selectedPoemComments;
  },
};

const actions = {
  getPoem(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getPoemUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedPoem', response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPoems(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(data.url, data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setPoemsPaginator', response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPoemTags(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getPoemTagsUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  cratePoemComment(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.poemCommentUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          if (!data.poem_comment_discussion_id) {
            let selectedPoemComments = context.getters.selectedPoemComments;
            selectedPoemComments.push(response.data.data);
            context.commit('setSelectedPoemComments', selectedPoemComments);
          }
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPoemComments(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.poemCommentUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          if (!data.poem_comment_id) {
            context.commit('setSelectedPoemComments', response.data.data);
          }
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deletePoemComment(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(globals.APIs.poemCommentUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          let selectedPoemComments = context.getters.selectedPoemComments;
          if (!data.comment.poem_comment_discussion_id) {
            if (data.comment.comments.length === 0) {
              selectedPoemComments = selectedPoemComments.filter((comment) => comment.id !== data.comment.id);
            } else {
              let selectedPoemComment = selectedPoemComments.find((comment) => comment.id === data.comment.id);
              selectedPoemComment.user = null;
              selectedPoemComment.text = 'User Deleted Comment';
            }
          } else {
            let discussionComment = selectedPoemComments.find((comment) => comment.id === data.comment.poem_comment_discussion_id);
            discussionComment.comments = discussionComment.comments.filter((comment) => comment.id !== data.comment.id);
          }

          context.commit('setSelectedPoemComments', selectedPoemComments);
          resolve(response.data.data);
        })
        .catch(() => {
          reject(error);
        });
    });
  },
  reportPoemComment(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.reportPoemCommentUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  togglePoemBookmark(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.togglePoemBookmarkUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  togglePoemLike(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.togglePoemLikeUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  subscribeToKlaviyoList(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.subscribeToKlaviyoListUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          let user = context.rootGetters.loggedInUser;
          if (user) {
            let notificationSetting = user.profile.notification_setting;
            notificationSetting.poems = ['Email'];
            user.profile.notification_setting = notificationSetting;
            context.commit('updateLoggedInUser', user);
          }
          resolve(response.data.data);
        })
        .catch(() => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setPoemsPaginator(state, data) {
    state.lectures = data;
  },
  setSelectedPoem(state, data) {
    state.selectedPoem = data;
  },
  setSelectedPoemComments(state, data) {
    state.selectedPoemComments = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
