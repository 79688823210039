<template>
  <div :class="animation" class="w-full max-w-lg mt-16 tile">
    <div class="w-full">
      <div class="object-contain overflow-hidden rounded-t-xl h-52">
        <img src="@/assets/account-setup-complete.jpg" />
      </div>
    </div>
    <div class="flex justify-center w-full">
      <div class="relative z-20 w-20 h-20 -mt-10 border-4 border-white border-solid rounded-full bg-primary">
        <i class="pt-5 text-white pi pi-check" style="font-size: 2rem" />
      </div>
    </div>

    <div v-if="isGP(loggedInUser)" class="px-8 pt-4 pb-8">
      <h1 class="text-2xl font-bold text-blue">Account Setup Complete</h1>
      <div class="seperator"></div>
      <div class="px-4">
        <p class="mb-6 text-sm text-gray-500">You're all set! You now have full access to Virtual Hallway. Ready to book your first consult? Or just want to check out the site?</p>
      </div>
      <div class="flex flex-row items-center justify-center mb-2 align-center">
        <Button @click="$router.push('/makereferral')" label="Get Started" />
      </div>
    </div>

    <div v-if="isSpecialist(loggedInUser)" class="px-8 pt-4 pb-8">
      <h1 class="text-2xl font-bold text-blue">Account Successfully Setup</h1>
      <div class="seperator"></div>
      <div class="px-4">
        <p class="mb-6 text-sm text-gray-500">Before you can start accepting consults, we require you to verify your account by uploading a copy of your photo ID <span v-if="actingUser.provider_type === 'FFS'">, and submit the required billing documentation</span>.</p>
      </div>
      <div class="flex flex-row items-center justify-center mb-2 align-center">
        <Button @click="$router.push('/verification')" label="Verify Account" />
      </div>
    </div>

    <div v-if="isOrgUnitHead(loggedInUser)" class="px-8 pt-4 pb-8">
      <h1 class="text-2xl font-bold text-blue">Welcome to Virtual Hallway! 🎉</h1>
      <div class="seperator"></div>
      <div class="px-4">
        <p class="mb-6 text-sm text-gray-500">Thank you for registering. We will be in touch shortly to get your practice group set up.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from 'primevue/button';

export default {
  components: {
    Button,
  },
  props: ['loggedInUser', 'propsUser'],

  data() {
    return {
      animation: 'animate-in',
      user: this.loggedInUser,
    };
  },
  computed: {
    ...mapGetters(['actingUser', 'isGP', 'isSpecialist', 'isOrgUnitHead', 'allProvinces']),
  },
};
</script>

<style></style>
