<template>
  <template v-if="!isLoading">
    <form v-if="!show2faLogin && !oauthRegistered" method="post" @submit.prevent="submit" class="mt-4">
      <template v-if="!isNativePlatform()">
        <div>
          <label for="email" class="text-sm font-display">Email Address</label>
          <InputText type="email" name="email" id="email" v-model="email" required :class="['w-full', { 'p-invalid': emailPasswordInvalid }]" maxLength="255" :disabled="isEmailDisabled" />
        </div>
        <div class="mt-2">
          <label class="text-sm font-display" for="password">Password</label>
          <Password name="password" id="password" v-model="password" required :feedback="false" :toggleMask="true" :class="['w-full', 'border-0', { 'p-invalid': emailPasswordInvalid }]" inputStyle="background-color:#fff!important; width: 100% !important;" maxLength="255" />
        </div>
        <div class="mt-8 mb-5">
          <Button type="submit" class="w-full p-button p-button-secondary" label="Login"></Button>
        </div>
        <div class="w-full mb-2 text-sm text-center text-gray-600 font-display">
          <router-link to="/passwordreset" exact-path> Forgot Password? </router-link>
        </div>
      </template>
      <template v-else>
        <div v-if="!askForBiometricLogin">
          <div class="mb-4 input-field">
            <label for="email" class="auth-input">Email</label>
            <ion-input type="email" name="email" id="email" autocomplete="email" required email :disabled="isEmailDisabled"></ion-input>
          </div>
          <div class="mb-4 input-field">
            <label class="auth-input" for="password">Password</label>
            <ion-input id="pwd" type="password" name="password" autocomplete="current-password" required></ion-input>
          </div>
          <div class="mt-8 mb-8">
            <Button type="submit" class="w-full p-button p-button-secondary" label="Login"></Button>
          </div>
          <div class="w-full mb-2 text-sm text-center text-gray-600 font-display">
            <router-link to="/passwordreset" exact-path> Forgot Password? </router-link>
          </div>
        </div>
        <div v-else>
          <div class="mt-8 mb-8">
            <Button class="w-full p-button p-button-primary" label="Login with Face ID" icon="fas fa-fingerprint" @click="performBiometricVerification"></Button>
          </div>
          <Button class="w-full p-button p-button-secondary p-button-text" label="Login Using Password" @click="askForBiometricLogin = false"></Button>
        </div>
      </template>
    </form>
    <div v-else-if="show2faLogin" class="mt-8">
      <p class="mb-5 success p-text-center">
        A verification code has been sent to your phone number
        {{ maskedPhoneNumber }}.
      </p>
      <p class="mt-3 mb-3 warning p-text-center">
        The code will expire in: {{ formatDateTimeIS(timer) }}
        <!-- This span is important to update the timer when countDown changes, without this
            span Vue does not reload the template. -->
        <span class="hidden">{{ countDown }}</span>
      </p>
      <div class="mb-4 input-field">
        <label for="email">Verification Code</label>
        <InputText type="text" v-model="verificationCode" required :class="['public-input']" maxLength="255" />
      </div>
      <div class="mb-4">
        <button class="w-full btn btn--main" @click="verifyOtp" v-on:keyup.enter="verifyOtp">Login</button>
      </div>
      <div class="mb-4 p-text-center">
        <Button label="Resend Verification Code" class="p-button-outlined p-button-rounded" @click="resendOtp" id="resendOTPButton" />
      </div>
    </div>
    <p class="error">
      {{ error }}
    </p>
    <p class="success">
      {{ success }}
    </p>
    <Loading z-index="99" v-model:active="isLoading" color="#5e76ff" background-color="#f0f2f4" opacity="100" :can-cancel="false" :is-full-page="true" />

    <Dialog v-model:visible="askForConfirmationToSaveBiometry" modal header="Save Credentials" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <p>Do you want to save this credential to login using Face ID?</p>
      <template #footer>
        <Button label="No" class="p-button p-button-outlined p-button-secondary" @click="confirmSaveBiometry(false)" />
        <Button label="Yes" class="p-button p-button-primary" @click="confirmSaveBiometry(true)" autofocus />
      </template>
    </Dialog>
    <div class="flex items-center w-full mt-5 font-display" v-if="!oauthRegistered">
      <div class="line-h-0.5 flex-grow border-b border-solid border-gray-500"></div>
      <div class="flex-1 mx-3 text-xs">Or continue with</div>
      <div class="line-h-0.5 flex-grow border-b border-solid border-gray-500"></div>
    </div>
    <div class="mt-5">
      <OAuth :ssoStateProp="ssoStateProp" />
      <div class="w-full mt-5 text-xs text-center">By continuing, I agree to the &nbsp;<EulaDialog /></div>
    </div>
    <div class="w-full mt-4 text-sm text-center text-gray-600 underline cursor-pointer font-display" id="unsetOauthRegisteredBtn" @click="unsetOauthRegistered()" :class="[{ hidden: !oauthRegistered }]">
      <span> Need Another Method to Sign In? </span>
    </div>
  </template>
  <div v-else class="mt-12 mb-16 py-28">
    <p class="mt-8 mb-3 text-lg font-bold text-center">Logging In</p>
    <ProgressBar mode="indeterminate" style="height: 0.2em" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Loading from 'vue-loading-overlay';
import Button from 'primevue/button';
import InlineMessage from 'primevue/inlinemessage';
import { Capacitor } from '@capacitor/core';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { IonInput } from '@ionic/vue';
import { NativeBiometric } from 'capacitor-native-biometric';
import { Device } from '@capacitor/device';
import { Browser } from '@capacitor/browser';
import OAuth from '@/views/public/OAuth.vue';

export default {
  props: ['lastLoggedInUserEmail', 'autoLoggedOut'],
  inject: ['mixpanel'],
  data() {
    return {
      email: '',
      password: '',
      invite_code: null,
      biometricEmail: '',
      error: '',
      emailPasswordInvalid: false,
      isLoading: false,
      show2faLogin: false,
      verificationCode: '',
      maskedPhoneNumber: '',
      countDown: 300,
      timer: new Date(0, 0, 0, 0, 4, 59),
      success: '',
      toggleTransition: false,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      askForBiometricLogin: false,
      isBiometricAvailable: false,
      deviceId: null,
      askForConfirmationToSaveBiometry: false,
      ssoState: null,
      isEmailDisabled: false,
      ssoStateProp: {},
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'onboardingForm', 'isGP', 'isCme', 'isOrgUnitHead', 'orgUnitUsers', 'isPreboarding', 'authState', 'oauthUserConversionCode']),
    oauthRegistered() {
      return JSON.parse(this.authState.oauthRegistered);
    },
  },
  components: {
    InputText,
    Password,
    Loading,
    Button,
    InlineMessage,
    IonInput,
    OAuth,
  },
  async created() {
    if (this.isNativePlatform()) {
      this.isBiometricAvailable = await NativeBiometric.isAvailable();
      this.isBiometricAvailable = this.isBiometricAvailable.isAvailable;
      if (this.isBiometricAvailable) {
        let credentials = await NativeBiometric.getCredentials({
          server: 'app.virtualhallway.ca',
        });
        if (credentials) {
          this.deviceId = await Device.getId();
          this.deviceId = this.deviceId.identifier;
          this.askForBiometricLogin = true;
          this.isBiometricAvailable = true;
        }
      }
    }
    let oauthProperties = localStorage.getItem('oauth_properties');
    if (oauthProperties) {
      this.isLoading = true;
      if (this.isNativePlatform()) {
        try {
          await Browser.close();
        } catch {
          this.showGenericErrorToast();
        }
      }
      this.isLoading = true;
      oauthProperties = JSON.parse(oauthProperties);
      localStorage.removeItem('oauth_properties');
      let testingEmail = null;
      if (this.$route.query.state) {
        this.invite_code = this.$route.query.state ? JSON.parse(this.$route.query.state).invite_code : null;
        testingEmail = JSON.parse(this.$route.query.state).testing_email;
      }
      this.registerOauth({
        oauth_code: this.$route.query.code,
        challenge_verifier: oauthProperties.challenge_verifier,
        oauth_provider: oauthProperties.oauth_provider,
        device_id: this.deviceId,
        is_mobile: this.isNativePlatform(),
        timezone: this.timezone,
        invite_code: this.invite_code,
        testing_email: testingEmail,
      })
        .then(async (res) => {
          this.isLoading = false;
          if (res.status == 202) {
            this.$store.commit('setOauthUserConversionCode', res.data.code);
            this.$router.push('/switchtooauth');
          } else {
            this.setOauthRegistered(oauthProperties.oauth_provider);
            this.handleSuccessfulLogin();
            this.registerMixpanel();
          }
        })
        .catch((res) => {
          this.isLoading = false;
          this.handleFailedLogin(res);
        });
    }
    if (this.$route.query.accept_invite) {
      this.invite_code = this.$route.query.c;
      this.ssoStateProp.invite_code = this.invite_code;
      this.email = this.$route.query.email;
      this.isEmailDisabled = true;
    }
    if (this.$route.query.redirect) {
      this.ssoStateProp.redirectFromRegistration = this.$route.query.redirect;
    }
  },
  methods: {
    ...mapActions(['login', 'verifyOtpForUserLogin', 'sendOtpToUser', 'storeExternalReferralRequestCode', 'registerOauth']),
    ...mapMutations(['setOauthRegistered']),
    async performBiometricVerification() {
      // The parameters here do not matter for iOS, the dialog content is set in info.plist
      NativeBiometric.verifyIdentity({
        reason: 'Allow biometric to easily and securely login to your account.',
        title: 'Log In Using Biometric',
        subtitle: 'Use biometric to easily and securely login to your account.',
        description: '',
      })
        .then(async () => {
          let credentials = await NativeBiometric.getCredentials({
            server: 'app.virtualhallway.ca',
          });
          if (credentials) {
            this.email = credentials.username;
            this.password = credentials.password;
            this.biometricEmail = credentials.username;
            this.submit();
          } else {
            this.askForBiometricLogin = false;
            this.isBiometricAvailable = false;
          }
        })
        .catch(() => {
          this.askForBiometricLogin = false;
        });
    },
    submit() {
      this.isLoading = true;
      this.error = '';
      this.success = '';
      this.$toast.removeAllGroups();
      if (this.isNativePlatform() && !this.askForBiometricLogin) {
        this.email = document.getElementById('email').value;
        this.password = document.getElementById('pwd').value;
      }
      this.login({
        email: this.email,
        password: this.password,
        timezone: this.timezone,
        device_id: this.deviceId,
        is_mobile: this.isNativePlatform(),
        invite_code: this.invite_code,
        oauth_user_conversion_code: this.oauthUserConversionCode?.code,
      })
        .then(async (res) => {
          if (res.status == 200) {
            if (res.data.user.email_verified_at) {
              this.isLoading = false;
              this.saveBiometricCredentials()
                .then(() => {
                  if (!this.askForConfirmationToSaveBiometry) {
                    this.handleSuccessfulLogin();
                  }
                })
                .catch(() => {
                  this.handleSuccessfulLogin();
                });
            } else {
              this.isLoading = false;
              this.$router.push({ path: '/home' });
            }
            this.registerMixpanel();
          }
          //// Status 202 means that an OTP has been sent for 2FA login
          else if (res.status == 202) {
            this.countDownTimer();
            this.isLoading = false;
            this.show2faLogin = true;
            this.maskedPhoneNumber = res.data.masked_phone_number;
          }
        })
        .catch((res) => {
          this.handleFailedLogin(res);
        });
    },
    async verifyOtp() {
      if (this.verificationCode == '') {
        this.error = 'Please enter the verification code that has been sent to your phone.';
      } else {
        this.isLoading = true;
        this.error = '';
        this.success = '';
        if (this.isBiometricAvailable) {
          this.deviceId = await Device.getId();
          this.deviceId = this.deviceId.identifier;
        }
        this.verifyOtpForUserLogin({
          email: this.email,
          verificationCode: this.verificationCode,
          device_id: this.deviceId,
        })
          .then((res) => {
            if (res.status == 200) {
              this.isLoading = false;
              this.saveBiometricCredentials()
                .then(() => {
                  if (!this.askForConfirmationToSaveBiometry) {
                    this.handleSuccessfulLogin();
                  }
                })
                .catch(() => {
                  this.handleSuccessfulLogin();
                });
              this.registerMixpanel();
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.error = err.data.error;
          });
      }
    },
    resendOtp() {
      this.isLoading = true;
      this.error = '';
      this.success = '';
      this.sendOtpToUser({ email: this.email })
        .then((res) => {
          this.isLoading = false;
          this.success = res.data.message;
          this.countDown = 300;
          this.countDownTimer();
          this.timer = new Date(0, 0, 0, 0, 4, 59);
        })
        .catch((err) => {
          this.isLoading = false;
          if (err.status == 500) {
            this.error = 'There was an error processing your request, please contact support@virtualhallway.ca';
          } else this.error = err.data.error;
        });
    },
    //// Countdown timer implemented with help from: https://stackoverflow.com/a/55773753/10052594
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.timer.setSeconds(this.timer.getSeconds() - 1);
          this.countDownTimer();
        }, 1000);
      } else {
        this.timer = new Date(0, 0, 0, 0, 0, 0);
      }
    },
    handleSuccessfulLogin() {
      let redirect = this.$route.query?.redirect;
      let lectureId = this.$route.query.state ? JSON.parse(this.$route.query.state).lecture_id : this.$route.query.lecture_id;
      const redirectFromRegistration = this.$route.query.state ? JSON.parse(this.$route.query.state).redirectFromRegistration : null;
      if (this.oauthUserConversionCode) {
        this.$store.commit('setGlobalDialogComponentName', 'AccountSwitchedToOauthDialog');
      }
      if (!this.onboardingForm.is_eula_agreed) {
        this.$router.push({ path: '/eula' });
      } else if (lectureId) {
        this.$router.push({
          path: '/lecture/' + lectureId,
          query: JSON.parse(this.$route.query.state),
        });
      } else if (redirect) {
        let params = this.$route.params;
        if (params.type == 'cortico_referral_request') {
          this.storeExternalReferralRequestCode(params.requestCode);
          this.$router.push({ name: 'MakeReferral', params: params });
        } else {
          this.$router.push(redirect || '/home');
        }
      } else if (redirectFromRegistration) {
        this.$router.push(redirectFromRegistration);
      } else if (this.invite_code && this.loggedInUser.status != 'Active') {
        this.$router.push({ path: '/account-setup' });
      } else if (this.isCme(this.loggedInUser)) {
        this.$router.push({ path: '/lectures' });
      } else if (this.isPreboarding(this.loggedInUser)) {
        this.$router.push({ path: '/account-setup' });
      } else if (this.isGP(this.loggedInUser)) {
        this.$router.push({ path: '/makereferral' });
      } else if (this.isOrgUnitHead(this.loggedInUser) || this.isBookingHallwayAdmin(this.loggedInUser)) {
        this.$router.push({ path: '/manageusers' });
      } else {
        this.$router.push({ path: '/home' });
      }
    },
    handleFailedLogin(res) {
      this.isLoading = false;
      this.emailPasswordInvalid = true;
      if (res.status === 401 || res.status === 403 || res.status === 404) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: res.data.message,
          life: 10000,
        });
      } else if (res.status === 429) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: 'Too many attempts, please try again later.',
          life: 10000,
        });
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'There was an error processing your request, please try again later or contact support@virtualhallway.ca.',
          life: 10000,
        });
      }
    },
    registerMixpanel() {
      this.mixpanel.identify(this.loggedInUser.id);
      const province = this.loggedInUser.practice_province ? this.loggedInUser.practice_province.name : 'Unknown';
      this.mixpanel.register_once({
        user_id: this.loggedInUser.id,
        unique_id: this.loggedInUser.id,
        distinct_id: this.loggedInUser.id,
        role: this.loggedInUser.role,
        providerType: this.loggedInUser.provider_type,
        province,
      });
      this.mixpanel.track('Login');
    },
    async saveBiometricCredentials() {
      if (Capacitor.getPlatform() === 'ios' && this.isBiometricAvailable) {
        if (this.biometricEmail != this.email) {
          this.askForConfirmationToSaveBiometry = true;
        } else {
          SavePassword.promptDialog({
            username: this.email,
            password: this.password,
          });
          NativeBiometric.setCredentials({
            username: this.email,
            password: this.password,
            server: 'app.virtualhallway.ca',
          });
        }
      }
    },
    confirmSaveBiometry(confirmation) {
      if (confirmation) {
        NativeBiometric.deleteCredentials({
          server: 'app.virtualhallway.ca',
        });
        this.askForConfirmationToSaveBiometry = false;
        this.biometricEmail = this.email;
        this.saveBiometricCredentials();
      } else {
        this.askForConfirmationToSaveBiometry = false;
      }
      this.handleSuccessfulLogin();
    },
    unsetOauthRegistered() {
      this.setOauthRegistered(null);
      location.reload();
    },
    setEmailPassword(email, password) {
      this.email = email;
      this.password = password;
    },
  },
  mounted() {
    this.$toast.removeAllGroups();
    this.toggleTransition = true;
    // document.getElementById('pwd').children[0].addEventListener('change', (e) => {
    //   this.password = e.target.value;
    // });
  },
};
</script>

<style>
input.native-input.sc-ion-input {
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  padding: 5px;
}
div.label-text-wrapper.sc-ion-input.sc-ion-input-s {
  font-size: 0.8rem;
}
</style>
<style scoped>
.login-form-heading-icon {
  color: var(-vh-bright-light-blue);
  font-size: 3rem;
}

.input-field a {
  display: block;
  color: #a2a2a2;
  text-align: center;
  font-size: 18px;
  padding-bottom: 0px;
}

.input-field label {
  line-height: 1.3;
  font-size: 14px;
  padding-left: 5px;
  /* padding-left: 15px; */
  color: var(--vh-label-gray);
}

.slide-up-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-up-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-up-fade-enter-from,
.slide-up-fade-leave-to {
  transform: translatey(50px);
  opacity: 0;
}
</style>
