<template>
  <div class="relative flex flex-col justify-between w-full cursor-pointer tile animated fade-in-up animation-delay rounded-xl">
    <Skeleton height="16rem" class="relative w-full h-64 overflow-hidden v-r-top" style="overflow: hidden !important"></Skeleton>
    <div class="flex flex-col justify-between p-4 text-left text-gray-600 rounded-b-xl grow">
      <div class="flex flex-col font-bold">
        <div class="pb-2 mb-2 border-b text-md font-display">
          <Skeleton height="1.3rem" width="20rem" class="mb-2"></Skeleton>
          <Skeleton height="1.3rem" width="18rem"></Skeleton>
        </div>
        <div class="flex flex-row items-center justify-between w-full border-round">
          <div class="flex flex-col text-sm">
            <Skeleton height="1.2rem" width="16rem" class="w-full mt-1"></Skeleton>
          </div>
        </div>
      </div>
      <Skeleton height="2.4rem" class="w-full"></Skeleton>
    </div>
  </div>
</template>

<script>
export default {};
</script>
