<template>
  <div class="p-2 text-sm privacy-content-left">
    <h2 class="text-2xl font-bold">Platform Privacy Policy</h2>
    <div>
      <div>
        <p>
          <span><span>**Last Updated: July 17, 2024**</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>Virtual Hallway Consults Inc. (&ldquo;Virtual Hallway</span><span>&rdquo;,</span><span>&nbsp;&ldquo;Us</span><span>&rdquo;,</span><span>&nbsp;&ldquo;We</span><span>&rdquo;,</span><span>&nbsp;or &ldquo;Our&rdquo;)&nbsp;</span><span>operates</span><span>&nbsp;the&nbsp;</span></span
          ><a href="http://www.virtualhallway.ca/" target="_blank" rel="noreferrer noopener"
            ><u
              ><span><span>www.virtualhallway.ca</span></span></u
            ></a
          ><span><span>&nbsp;Website (the &ldquo;Website&rdquo;), and the iOS app&nbsp;</span></span
          ><a href="https://apps.apple.com/ca/app/virtual-hallway/id6451244020" target="_blank" rel="noreferrer noopener"
            ><u
              ><span><span>https://apps.apple.com/ca/app/virtual-hallway/id6451244020</span></span></u
            ></a
          ><span><span>&nbsp;in which users may sign up for or subscribe to Our proprietary software as a service platform designed to virtually connect referring clinicians with consultant clinicians (the &ldquo;Platform&rdquo;). This Platform Privacy Policy (the &ldquo;Policy&rdquo;) informs users of the Platform (&ldquo;You&rdquo; or &ldquo;Your&rdquo;) of Our policies&nbsp;</span><span>regarding</span><span>&nbsp;the collection, use, and disclosure of personally identifiable information and personal health information when You use the Platform and the choices You have associated with the data You&nbsp;</span><span>submitted</span><span>&nbsp;to Us. We use Your data to provide and improve the Platform.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>Unless otherwise&nbsp;</span><span>stated</span><span>&nbsp;</span><span>herein</span><span>, defined terms in this Policy have the same meaning as&nbsp;</span><span>set forth in</span><span>&nbsp;Our End User License Agreement (&ldquo;EULA&rdquo;).</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div class="mb-4">
        <p>
          <span><span>By using the Platform,&nbsp;</span><span style="border-bottom: 1px solid transparent">You</span><span>&nbsp;agree to the collection and use of information under this Policy.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <strong
            ><span><span>1. Policy Scope</span></span></strong
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>1.1 This Policy describes:</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; a. How We collect, use,&nbsp;</span><span>disclose</span><span>, and protect any personally identifiable information and personal health information&nbsp;</span><span>submitted</span><span>&nbsp;by You through the Platform.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; b. The types of information We may collect from You or that You may provide when You visit the Platform.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; c. Our practices for collecting, using,&nbsp;</span><span>maintaining</span><span>, protecting, and&nbsp;</span><span>disclosing</span><span>&nbsp;that information.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>1.2 This Policy applies to information We collect, use, or&nbsp;</span><span>disclose</span><span>&nbsp;about You:</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; a. On the Platform.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; b. In email, text, and other electronic messages between You and Virtual Hallway in relation to the Platform.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>1.3 This Policy DOES NOT include any policies&nbsp;</span><span>regarding</span><span>&nbsp;Our collection, use, and disclosure of personal information or any information through Your interaction with non-Platform part of the Website, which is governed by Our General Privacy Policy, available at (www.virtualhallway.ca/privacy-policy). Your communication with Us&nbsp;</span><span>regarding</span><span>&nbsp;the non-Platform part of the Website is also covered by the General Privacy Policy.&nbsp;</span><span>In the&nbsp;</span><span>event of</span><span>&nbsp;a conflict between this Policy and the General Privacy Policy, this Policy must prevail.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>1.4 This Policy DOES NOT apply to information that You provide to or is collected by any third party, through any application or content (including advertising) that may link to or be accessible from or on the Platform.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>1.5 We will only use Your personal information&nbsp;</span><span>in accordance with</span><span>&nbsp;this Policy unless otherwise required by applicable law. We take steps to ensure that the personal information that We collect from You is adequate, relevant, not excessive, and used for limited purposes. Privacy laws in Canada&nbsp;</span><span>generally define</span><span>&nbsp;&ldquo;personal information&rdquo; as any information about an identifiable individual, which includes information that can be used on its own or with other information to&nbsp;</span><span>identify</span><span>, contact, or&nbsp;</span><span>locate</span><span>&nbsp;a single person. &ldquo;Personal health information&rdquo;&nbsp;</span><span>generally refers</span><span>&nbsp;to&nbsp;</span><span>identifying</span><span>&nbsp;information about an individual as it relates to:</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>&nbsp; a. Information concerning the physical or mental health of the individual.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; b. Information concerning any health service provided to the individual.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; c. Information concerning the donation by the individual of any body part or any bodily substance of the individual or information derived from the testing or examination of a body part or bodily substance of the individual.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; d. Information that is collected&nbsp;</span><span style="border-bottom: 1px solid transparent">in the course of</span><span>&nbsp;providing health services to the individual.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; e. Information that is collected incidentally to the provision of health services to the individual.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>For this Policy, personal information also includes &ldquo;personal health information&rdquo;/&quot;health information&quot;, which has the same meaning under most Canadian health privacy acts including: the Nova Scotia Personal Health Information Act (PHIA), the Ontario Personal Health Information Protection Act (PHIPA) and the Alberta Health Information Act (HIA) as amended from time to time.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>1.6 Please read this Policy carefully to understand Our policies and practices for collecting, processing, and storing Your information. If You do not agree with Our policies and practices,&nbsp;</span><span style="border-bottom: 1px solid transparent">Your</span><span>&nbsp;choice is not to use Our Platform. By accessing or using this Platform,&nbsp;</span><span style="border-bottom: 1px solid transparent">You</span><span>&nbsp;indicate that You understand, accept, and consent to the practices described in this Policy. This Policy may change from time to time (see Changes to This Privacy Policy below). Your continued use of the Platform after We make changes&nbsp;</span><span>indicates</span><span>&nbsp;that You accept and consent to those changes, so please check the policy periodically for updates.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <strong
            ><span><span>2. Information Collection and Use</span></span></strong
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>We collect several&nbsp;</span><span>different types</span><span>&nbsp;of information for various purposes to provide and improve Our Platform to You.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>2.1 Types of Information Collected</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>2.1.1 Personal Data</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; a. While using Our Platform,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;may ask You to provide Us with certain personally identifiable information. Personal Data may include but is not limited to:</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; &nbsp;&nbsp;</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; &nbsp;&nbsp;</span><span style="border-bottom: 1px solid transparent">i</span><span>. Data used to contact or&nbsp;</span><span>identify</span><span>&nbsp;You, such as Email address, First name and last name, Your device&rsquo;s Internet Protocol address, phone number, and Your address and postal code.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; &nbsp;&nbsp;</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; &nbsp; ii. For identity verification and fraud prevention,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;may ask You to provide Us with Your government-issued identification, proof of insurance and liability coverage, and other relevant information from time to time.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>&nbsp; b. We may collect&nbsp;</span><span>additional</span><span>&nbsp;Personal Data when You use the Platform as You may supply Us with personally identifiable information and personal health information, which include:</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; &nbsp;&nbsp;</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; &nbsp;&nbsp;</span><span style="border-bottom: 1px solid transparent">i</span><span>. Your Patient&rsquo;s information, including their personal health information, such as name, diagnosis, health card number, date of birth,&nbsp;</span><span>provincial health insurance number</span><span>.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; &nbsp;&nbsp;</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; &nbsp; ii. Names of&nbsp;</span><span>Referring Clinician</span><span>&nbsp;and&nbsp;</span><span>Consultant Clinician</span><span>.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; &nbsp;&nbsp;</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; &nbsp; iii. Other relevant information such as start and end time of the consultation.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>In any case,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;will only collect and&nbsp;</span><span>maintain</span><span>&nbsp;Patient personal health information that You provide to Us in relation to the services of the Platform.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>2.1.2 Business and Professional Practice Data</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; a. We may also collect Business and Professional Practice Data from You for Your registration for or subscription to the Platform and for verifying Your professional practice status.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; b. Data we collected includes Your business phone number, province of practice, Your practitioner type, professional society membership number, and Your address of practice.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; c. We may also collect necessary billing information from Your practice, such as Your business&rsquo; bank account information, name of financial institution, and Your business&nbsp;</span><span>address, so that We can act as Your agent in collecting the bill payments from provincial health insurance programs.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; d. If You subscribe to the Platform,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;may also collect Your credit card information to&nbsp;</span><span>facilitate</span><span>&nbsp;Your subscription fees to Us, including the last 4 digits of Your credit card number, credit card type, billing address, and postal code.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>2.</span><span>1.3</span><span>&nbsp;Usage Data</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>We may also collect information relating to how You access and use the Platform (&ldquo;Usage Data&rdquo;). This Usage Data may include information such as browser type, browser version, the pages of Our Platform that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>2.</span><span>1.4</span><span>&nbsp;Tracking &amp; Cookies Data</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>We use cookies and similar tracking technologies to track the activity on Our Platform and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to Your browser from a website and stored on Your device. Tracking technologies also used are web beacons, tags, and scripts to collect and track information and to improve and analyze the Platform. You can instruct Your browser to refuse all cookies or to&nbsp;</span><span>indicate</span><span>&nbsp;when a cookie is being sent. However, if You do not accept cookies,&nbsp;</span><span style="border-bottom: 1px solid transparent">You</span><span>&nbsp;may not be able to use some portions of Our Platform. Examples of Cookies We use:</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>&nbsp; a. Session Cookies. We use Session Cookies to&nbsp;</span><span>operate</span><span>&nbsp;Our Platform.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; b. Preference Cookies. We use Preference Cookies to remember Your preferences and various settings.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; c. Security Cookies. We use Security Cookies for security purposes.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <strong
            ><span><span>3. How We Collect Information About You</span></span></strong
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>We use different methods to collect Your information, including through:</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>3.1 Direct interactions</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>&nbsp; a. With You when You provide it to us, for example, by filling in forms or corresponding with Us by phone, email, or otherwise.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>3.2 Automated technologies or interactions</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>&nbsp; a. As You navigate through the Platform. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>3.3 Third parties or publicly available sources</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>&nbsp; a. For example, Our business or advertising partners.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <strong
            ><span><span>4. Information You Provide to Us</span></span></strong
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>The information We collect directly from You on or through Our Platform may include:</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>4.1</span><span>&nbsp;Information that You provide by filling in forms on Our Platform</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>&nbsp; a. Interacting with the Platform&rsquo;s functionalities, using Our Platform to&nbsp;</span><span>facilitate</span><span>&nbsp;consultation with a Specialist or a Primary Care Provider, uploading to the Platform, posting any material, making payments to Our Platform services, or requesting further services. We may also ask You for information when You enter a conference or promotion sponsored by us, and when You report a problem to Us in relation to the Platform.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>4.2</span><span>&nbsp;Records and copies of Your correspondence (including email addresses)</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>&nbsp; a. If You contact Us.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <strong
            ><span><span>5</span></span></strong
          ><span>. Use of Your Information</span><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>5</span><span>.1 Virtual Hallway uses the collected data for various purposes:</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; a. To provide Our medical consultation management services on the Platform and&nbsp;</span><span>maintain</span><span>&nbsp;the Platform.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; b. To&nbsp;</span><span>notify You</span><span>&nbsp;about changes to the Platform.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; c. To allow You to&nbsp;</span><span>participate</span><span>&nbsp;in interactive features of Our Platform when You choose to do so.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; d. To provide customer care and support.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; e. To process payments and medical bills on behalf of Your business.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; f. To receive payments from You.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; g. To provide analysis or valuable information so that We can improve the Platform.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; h. To&nbsp;</span><span>monitor</span><span>&nbsp;the usage of the Platform.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span style="border-bottom: 1px solid transparent">i</span><span>. To detect, prevent and address technical issues.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; j. For any other purpose with Your consent.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>5.2 We may also use Your information to contact You about Our own and&nbsp;</span><span style="border-bottom: 1px solid transparent">third-parties</span><span>&apos; goods and services that may be of interest to You, as&nbsp;</span><span>permitted</span><span>&nbsp;by law. If You do not want Us to use Your information in this way, please e-mail Us at info@virtualhallway.ca. For more information, see Your Choices Section below.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>5.3 We will only share personal health information to a third-party entity and any other person&nbsp;</span><span>in accordance with</span><span>&nbsp;applicable privacy and security laws and with the terms and conditions of the EULA.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>5.4 We may use the information We have collected from You to enable Us to display advertisements to Our advertisers&apos; target audiences. Even though We do not&nbsp;</span><span>disclose</span><span>&nbsp;Your personal information for these purposes without Your consent, if You click on or otherwise interact with an advertisement on the Website, the advertiser may collect information about You, in which We do not have control. For example, the advertiser may&nbsp;</span><span>identify</span><span>&nbsp;You as their targeted audience.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>6. Data Processing and Transfer</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>6.1 Please note that We store and process Your personal information, including all Personal Data, in Canada. If You are&nbsp;</span><span>located</span><span>&nbsp;outside Canada and choose to provide information to us,&nbsp;</span><span style="border-bottom: 1px solid transparent">Your</span><span>&nbsp;consent to this Policy followed by Your submission of such information&nbsp;</span><span>represents</span><span>&nbsp;Your agreement to that transfer.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>6.2 We may transfer personal information that We collect or that You provide as described in this Policy to contractors, Our Service Providers (defined hereafter), and other third parties We use to support Our business (such as analytics and search engine providers that assist Us with Website improvement and optimization) and who are contractually obligated to keep personal information confidential, use it only for the purposes for which We disclose it to them, and to process the personal information with the same standards set out in this Policy.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <strong
            ><span><span>7. Disclosure of Your Information</span></span></strong
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>7.1 Virtual Hallway may&nbsp;</span><span>disclose</span><span>&nbsp;aggregated information about Our users, and information that does not&nbsp;</span><span>identify</span><span>&nbsp;any individual, without restriction.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>7.2 Virtual Hallway may&nbsp;</span><span>disclose</span><span>&nbsp;personally identifiable information that We&nbsp;</span><span style="border-bottom: 1px solid transparent">collect</span><span>&nbsp;or You provide in the good faith belief that such action is necessary:</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; a. To&nbsp;</span><span>comply with</span><span>&nbsp;Our legal obligations, including to respond to any government or regulatory request,&nbsp;</span><span>in accordance with</span><span>&nbsp;applicable law.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; b. To provide services of this Platform&nbsp;</span><span>in accordance with</span><span>&nbsp;the EULA, such as&nbsp;</span><span>facilitating</span><span>&nbsp;the consultation between the Primary Care Provider and the Specialist and&nbsp;</span><span>facilitating</span><span>&nbsp;medical billing between You and Billing Agent.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; c. To fulfill the purpose for which You provide it.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; d. To enforce or apply Our terms and conditions and other agreements, including for billing and collection purposes.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; e. In accordance with applicable law, to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or&nbsp;</span><span>all of</span><span>&nbsp;Virtual Hallway&rsquo;s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Virtual Hallway is among the assets transferred.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; f. To protect and defend the rights or property of Virtual Hallway.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; g. To prevent or investigate&nbsp;</span><span>possible wrongdoing</span><span>&nbsp;in connection with Our services.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span>&nbsp; h. To protect the personal safety of users of the Platform or the public.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
      <div>
        <p><span></span><span>&nbsp;</span></p>
      </div>
      <div>
        <p>
          <span><span style="border-bottom: 1px solid transparent">i</span><span>. To protect against Our legal liability.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <strong
            ><span><span>8. Security of Data</span></span></strong
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>8.1 The security of your personal information is&nbsp;</span><span>very important</span><span>&nbsp;to us. We use commercially acceptable means and industrial standard measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. We store all information you provide to us behind firewalls on secure servers hosted by our Service Provider. Any payment transactions and personal information You provided on or through the Platform will be encrypted using SSL technology.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>8.2 The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Platform, you&nbsp;</span><span>are responsible for</span><span>&nbsp;keeping this password confidential. We ask you not to share your password with anyone.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>8.3 Unfortunately, the transmission of information via the Internet or method of electronic storage is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information&nbsp;</span><span>transmitted to our&nbsp;</span><span style="border-bottom: 1px solid transparent">Website</span><span>. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <strong
            ><span><span>9. Retention of Data</span></span></strong
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>9.1 Except as otherwise&nbsp;</span><span>permitted</span><span>&nbsp;or required by applicable law or regulation,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;will only&nbsp;</span><span>retain</span><span>&nbsp;Your personal information and Patient personal health information for as long as necessary to fulfill the purposes We collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. Under some circumstances,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;may anonymize Your personal information so that it can no longer be associated with You. We reserve the right to use such anonymous and de-identified data for any legitimate business purpose without further notice to You or Your consent.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>9.2 Please refer to Our EULA as it sets forth specific rules&nbsp;</span><span style="border-bottom: 1px solid transparent">with regard to</span><span>&nbsp;the retention of&nbsp;</span><span style="border-bottom: 1px solid transparent">Patient</span><span>&nbsp;personal health information.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <strong
            ><span><span>10. Your Choices</span></span></strong
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>We strive to provide You with choices&nbsp;</span><span>regarding</span><span>&nbsp;the personal information You provide to Us. We have created mechanisms to provide You with the following control over Your information:</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>10.1 Tracking Technologies and Advertising</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>&nbsp; a. You can set Your browser to refuse all or some browser cookies, or to alert You when cookies are being sent. If You disable or refuse cookies, please note that some parts of the Platform may not be accessible or may not function properly.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>10.2 Promotional Offers from Us</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>&nbsp; a. If You have opted in to receive certain emails from Us but no longer wish to have Your contact information used by the Company to promote Our own or third parties&apos; products or services,&nbsp;</span><span style="border-bottom: 1px solid transparent">You</span><span>&nbsp;can opt-out by sending Us an email&nbsp;</span><span>stating</span><span>&nbsp;Your request to info@virtualhallway.ca. If we have sent You a promotional email,&nbsp;</span><span style="border-bottom: 1px solid transparent">You</span><span>&nbsp;may unsubscribe by clicking the unsubscribe link we have included in the email or change Your preferences in Your account profile.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>10.3 Third Party and Internet-based Advertising</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>&nbsp; a. We will not provide Your personal information to third parties, except for the purpose to provide Our services to You and otherwise&nbsp;</span><span>states</span><span>&nbsp;in the EULA or&nbsp;</span><span>herein</span><span>. However, we cannot control third parties&apos; collection or use of Your information to serve interest-based advertising. However, these third parties may provide You with ways to choose not to&nbsp;</span><span>have Your information collected or used in this way. You can opt out of several third-party ad servers&apos; and networks&apos; cookies simultaneously by using an opt-out tool created by the Digital Advertising Alliance of Canada. You can also access these websites to learn more about online behavioural advertising and how to stop websites from placing cookies on Your device. Opting out of a network does not mean You will no longer receive online advertising. It does mean that the network from which You opted out will no longer deliver ads tailored to Your web preferences and usage patterns.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <strong
            ><span><span>11. Accessing and Correcting Your Personal Information</span></span></strong
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>11.1 By law You have the right to request access to and to correct the personal information that we hold about you. You may review and change some of the personal information by logging into the Platform through the Website and visiting Your account profile page. Please keep Us informed if&nbsp;</span><span style="border-bottom: 1px solid transparent">Your</span><span>&nbsp;personal information changes. For any personal information that You cannot change by visiting Your account profile page,&nbsp;</span><span style="border-bottom: 1px solid transparent">You</span><span>&nbsp;may send Us an email at privacy@virtualhallway.ca to request access to, correct, or&nbsp;</span><span>delete</span><span>&nbsp;any personal information that You have provided to Us.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>11.2 Subject to applicable privacy law, a Patient may request access to their personal information and personal health information hosted by Us by sending Us an email at privacy@virtualhallway.ca to request access to, correct, or&nbsp;</span><span>delete</span><span>&nbsp;any personal information that You have provided to Us.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>11.3 For either situation in paragraph (a) or (b) above,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;may not accommodate a request to change information if We believe the change would violate any law or legal requirement or cause the information to be incorrect.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>11.4 In certain circumstances We may need to&nbsp;</span><span>delete</span><span>&nbsp;Your account to&nbsp;</span><span>delete</span><span>&nbsp;Your personal information. In such cases,&nbsp;</span><span style="border-bottom: 1px solid transparent">You</span><span>&nbsp;consent to Us&nbsp;</span><span>deleting</span><span>&nbsp;Your account for this purpose.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>11.5 We may request specific information from the person requesting access to their personal information to help Us confirm their identity and their right to access, and to provide You with the personal information that We hold about them (or their Patient) or make their requested changes. If We cannot provide You with access to the personal information,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;will inform them of the reasons why, subject to any legal or regulatory restrictions. We will provide access to their personal information, subject to exceptions set out in applicable privacy legislation.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>11.6 If any person is concerned about Our response or would like to correct the information provided, the person may contact Our Privacy Officer at privacy@virtualhallway.ca.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>12. Withdraw Consent</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>12.1 Where a person has provided consent to the collection, use, and transfer of the person&rsquo;s personal information, the person may have the legal right to withdraw consent under certain circumstances. To withdraw consent, if applicable, contact Us at privacy@virtualhallway.ca. Please note that if the person withdraws consent,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;may not be able to provide the person with a particular product or service. We will explain the impact to the person at the time to help You with Your decision.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>13. Third Party Providers</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>13.1 From time to time,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;may engage with or employ third-party entities and individuals (&ldquo;Service Providers&rdquo;) for&nbsp;</span><span>maintaining</span><span>&nbsp;functionalities of and making changes to the Platform, to host information and data of</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>&nbsp;the Platform, to process payment on behalf of You, or to collect your payment of subscription fees of the Platform.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>13.2 Service Providers have access to personal information&nbsp;</span><span>submitted</span><span>&nbsp;by You or collected by Us only to perform these tasks on Our behalf and are&nbsp;</span><span>obligated</span><span>&nbsp;under contractual arrangements not to&nbsp;</span><span>disclose</span><span>&nbsp;or use it for any other purpose and to process the personal information with the same standards set out in this Policy.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>13.3 You acknowledge that while Virtual Hallway strives to protect privacy and security of personal information You&nbsp;</span><span>submit</span><span>&nbsp;or We collect,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;cannot guarantee our Service Providers will adhere to the same level of protection and security.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>13.4 You further acknowledge that, from time to time, Service Providers may also access or process de-identified Personal Information to&nbsp;</span><span>assist</span><span>&nbsp;Us in analyzing how Our Website is used.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <strong
            ><span><span>14. Links to Other Sites</span></span></strong
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>The Platform may include plug-ins, services, social networks, applications, or links to third-party websites. Clicking on those links or enabling those connections may allow the third party to collect or share data about You. If You follow a link to a third-party website or engage a third-party plug-in, please note that these third parties have their own privacy policies, and We do not accept any responsibility or liability for the practices of third-party websites or their policies. We do not control these third-party websites or applications, and We encourage You to read the privacy policy of every website or application You visit.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <strong
            ><span><span>15. Children&rsquo;s Privacy</span></span></strong
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>15.1 You&nbsp;</span><span>represent</span><span>&nbsp;and warrant that You are over the majority age of your&nbsp;</span><span>jurisdiction</span><span>&nbsp;or age of 19 (whichever is higher) when You access or use the Platform. Our Platform&nbsp;</span><span>does not address or intend to address any User under the age of&nbsp;</span><span style="border-bottom: 1px solid transparent">the majority of</span><span>&nbsp;Your&nbsp;</span><span>jurisdiction</span><span>; or any User under 19. We do not knowingly collect personal information from such user.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>15.2 We collect personal information, including personal health information, of a child Patient&nbsp;</span><span style="border-bottom: 1px solid transparent">under age</span><span>&nbsp;of 13, only if the child&rsquo;s parent(s) or legal guardian(s) have provided informed consent to the Primary Care Provider for the child&rsquo;s personal information to be stored, processed, or transferred through the Platform. If We learn&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;have collected or received personal information from a Patient who is a child under the age of 13 without verification of an informed parental consent, we will&nbsp;</span><span>delete</span><span>&nbsp;that information. If You believe&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;might have any information from or about a child under the age of 13, please contact Virtual Hallway at privacy@virtualhallway.ca.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <strong
            ><span><span>16. Changes to This Policy</span></span></strong
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>We may update Our Policy from time to time. We will&nbsp;</span><span>notify You</span><span>&nbsp;of any changes by posting the new Policy on this page. We will let You know via email and/or a prominent notice on Our Platform, prior to the change becoming effective and update the &ldquo;Effective Date&rdquo; at the top of this Policy. You are&nbsp;</span><span>advised to review</span><span>&nbsp;this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <strong
            ><span><span>Contact Us</span></span></strong
          ><span>&nbsp;</span>
        </p>
      </div>

      <div>
        <p>
          <span><span>If You have any questions about this Platform Privacy Policy, please contact Us by email: privacy@virtualhallway.ca.</span></span
          ><span>&nbsp;</span>
        </p>
      </div>
    </div>
    <div>
      <p>
        <span><span>**Last Updated: July 17, 2024**</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>Virtual Hallway Consults Inc. (&ldquo;Virtual Hallway</span><span>&rdquo;,</span><span>&nbsp;&ldquo;Us</span><span>&rdquo;,</span><span>&nbsp;&ldquo;We</span><span>&rdquo;,</span><span>&nbsp;or &ldquo;Our&rdquo;)&nbsp;</span><span>operates</span><span>&nbsp;the&nbsp;</span></span
        ><a href="http://www.virtualhallway.ca/" target="_blank" rel="noreferrer noopener"
          ><u
            ><span><span>www.virtualhallway.ca</span></span></u
          ></a
        ><span><span>&nbsp;Website (the &ldquo;Website&rdquo;), and the iOS app&nbsp;</span></span
        ><a href="https://apps.apple.com/ca/app/virtual-hallway/id6451244020" target="_blank" rel="noreferrer noopener"
          ><u
            ><span><span>https://apps.apple.com/ca/app/virtual-hallway/id6451244020</span></span></u
          ></a
        ><span><span>&nbsp;in which users may sign up for or subscribe to Our proprietary software as a service platform designed to virtually connect referring clinicians with consultant clinicians (the &ldquo;Platform&rdquo;). This Platform Privacy Policy (the &ldquo;Policy&rdquo;) informs users of the Platform (&ldquo;You&rdquo; or &ldquo;Your&rdquo;) of Our policies&nbsp;</span><span>regarding</span><span>&nbsp;the collection, use, and disclosure of personally identifiable information and personal health information when You use the Platform and the choices You have associated with the data You&nbsp;</span><span>submitted</span><span>&nbsp;to Us. We use Your data to provide and improve the Platform.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>Unless otherwise&nbsp;</span><span>stated</span><span>&nbsp;</span><span>herein</span><span>, defined terms in this Policy have the same meaning as&nbsp;</span><span>set forth in</span><span>&nbsp;Our End User License Agreement (&ldquo;EULA&rdquo;).</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>By using the Platform,&nbsp;</span><span style="border-bottom: 1px solid transparent">You</span><span>&nbsp;agree to the collection and use of information under this Policy.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <strong
          ><span><span>1. Policy Scope</span></span></strong
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>1.1 This Policy describes:</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; a. How We collect, use,&nbsp;</span><span>disclose</span><span>, and protect any personally identifiable information and personal health information&nbsp;</span><span>submitted</span><span>&nbsp;by You through the Platform.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; b. The types of information We may collect from You or that You may provide when You visit the Platform.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; c. Our practices for collecting, using,&nbsp;</span><span>maintaining</span><span>, protecting, and&nbsp;</span><span>disclosing</span><span>&nbsp;that information.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>1.2 This Policy applies to information We collect, use, or&nbsp;</span><span>disclose</span><span>&nbsp;about You:</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; a. On the Platform.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; b. In email, text, and other electronic messages between You and Virtual Hallway in relation to the Platform.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>1.3 This Policy DOES NOT include any policies&nbsp;</span><span>regarding</span><span>&nbsp;Our collection, use, and disclosure of personal information or any information through Your interaction with non-Platform part of the Website, which is governed by Our General Privacy Policy, available at (www.virtualhallway.ca/privacy-policy). Your communication with Us&nbsp;</span><span>regarding</span><span>&nbsp;the non-Platform part of the Website is also covered by the General Privacy Policy.&nbsp;</span><span>In the&nbsp;</span><span>event of</span><span>&nbsp;a conflict between this Policy and the General Privacy Policy, this Policy must prevail.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>1.4 This Policy DOES NOT apply to information that You provide to or is collected by any third party, through any application or content (including advertising) that may link to or be accessible from or on the Platform.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>1.5 We will only use Your personal information&nbsp;</span><span>in accordance with</span><span>&nbsp;this Policy unless otherwise required by applicable law. We take steps to ensure that the personal information that We collect from You is adequate, relevant, not excessive, and used for limited purposes. Privacy laws in Canada&nbsp;</span><span>generally define</span><span>&nbsp;&ldquo;personal information&rdquo; as any information about an identifiable individual, which includes information that can be used on its own or with other information to&nbsp;</span><span>identify</span><span>, contact, or&nbsp;</span><span>locate</span><span>&nbsp;a single person. &ldquo;Personal health information&rdquo;&nbsp;</span><span>generally refers</span><span>&nbsp;to&nbsp;</span><span>identifying</span><span>&nbsp;information about an individual as it relates to:</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>&nbsp; a. Information concerning the physical or mental health of the individual.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; b. Information concerning any health service provided to the individual.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; c. Information concerning the donation by the individual of any body part or any bodily substance of the individual or information derived from the testing or examination of a body part or bodily substance of the individual.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; d. Information that is collected&nbsp;</span><span style="border-bottom: 1px solid transparent">in the course of</span><span>&nbsp;providing health services to the individual.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; e. Information that is collected incidentally to the provision of health services to the individual.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>For this Policy, personal information also includes &ldquo;personal health information&rdquo;/&quot;health information&quot;, which has the same meaning under most Canadian health privacy acts including: the Nova Scotia Personal Health Information Act (PHIA), the Ontario Personal Health Information Protection Act (PHIPA) and the Alberta Health Information Act (HIA) as amended from time to time.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>1.6 Please read this Policy carefully to understand Our policies and practices for collecting, processing, and storing Your information. If You do not agree with Our policies and practices,&nbsp;</span><span style="border-bottom: 1px solid transparent">Your</span><span>&nbsp;choice is not to use Our Platform. By accessing or using this Platform,&nbsp;</span><span style="border-bottom: 1px solid transparent">You</span><span>&nbsp;indicate that You understand, accept, and consent to the practices described in this Policy. This Policy may change from time to time (see Changes to This Privacy Policy below). Your continued use of the Platform after We make changes&nbsp;</span><span>indicates</span><span>&nbsp;that You accept and consent to those changes, so please check the policy periodically for updates.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <strong
          ><span><span>2. Information Collection and Use</span></span></strong
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>We collect several&nbsp;</span><span>different types</span><span>&nbsp;of information for various purposes to provide and improve Our Platform to You.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>2.1 Types of Information Collected</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>2.1.1 Personal Data</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; a. While using Our Platform,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;may ask You to provide Us with certain personally identifiable information. Personal Data may include but is not limited to:</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; &nbsp;&nbsp;</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; &nbsp;&nbsp;</span><span style="border-bottom: 1px solid transparent">i</span><span>. Data used to contact or&nbsp;</span><span>identify</span><span>&nbsp;You, such as Email address, First name and last name, Your device&rsquo;s Internet Protocol address, phone number, and Your address and postal code.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; &nbsp;&nbsp;</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; &nbsp; ii. For identity verification and fraud prevention,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;may ask You to provide Us with Your government-issued identification, proof of insurance and liability coverage, and other relevant information from time to time.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>&nbsp; b. We may collect&nbsp;</span><span>additional</span><span>&nbsp;Personal Data when You use the Platform as You may supply Us with personally identifiable information and personal health information, which include:</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; &nbsp;&nbsp;</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; &nbsp;&nbsp;</span><span style="border-bottom: 1px solid transparent">i</span><span>. Your Patient&rsquo;s information, including their personal health information, such as name, diagnosis, health card number, date of birth,&nbsp;</span><span>provincial health insurance number</span><span>.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; &nbsp;&nbsp;</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; &nbsp; ii. Names of&nbsp;</span><span>Referring Clinician</span><span>&nbsp;and&nbsp;</span><span>Consultant Clinician</span><span>.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; &nbsp;&nbsp;</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; &nbsp; iii. Other relevant information such as start and end time of the consultation.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>In any case,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;will only collect and&nbsp;</span><span>maintain</span><span>&nbsp;Patient personal health information that You provide to Us in relation to the services of the Platform.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>2.1.2 Business and Professional Practice Data</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; a. We may also collect Business and Professional Practice Data from You for Your registration for or subscription to the Platform and for verifying Your professional practice status.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; b. Data we collected includes Your business phone number, province of practice, Your practitioner type, professional society membership number, and Your address of practice.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; c. We may also collect necessary billing information from Your practice, such as Your business&rsquo; bank account information, name of financial institution, and Your business&nbsp;</span><span>address, so that We can act as Your agent in collecting the bill payments from provincial health insurance programs.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; d. If You subscribe to the Platform,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;may also collect Your credit card information to&nbsp;</span><span>facilitate</span><span>&nbsp;Your subscription fees to Us, including the last 4 digits of Your credit card number, credit card type, billing address, and postal code.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>2.</span><span>1.3</span><span>&nbsp;Usage Data</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>We may also collect information relating to how You access and use the Platform (&ldquo;Usage Data&rdquo;). This Usage Data may include information such as browser type, browser version, the pages of Our Platform that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>2.</span><span>1.4</span><span>&nbsp;Tracking &amp; Cookies Data</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>We use cookies and similar tracking technologies to track the activity on Our Platform and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to Your browser from a website and stored on Your device. Tracking technologies also used are web beacons, tags, and scripts to collect and track information and to improve and analyze the Platform. You can instruct Your browser to refuse all cookies or to&nbsp;</span><span>indicate</span><span>&nbsp;when a cookie is being sent. However, if You do not accept cookies,&nbsp;</span><span style="border-bottom: 1px solid transparent">You</span><span>&nbsp;may not be able to use some portions of Our Platform. Examples of Cookies We use:</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>&nbsp; a. Session Cookies. We use Session Cookies to&nbsp;</span><span>operate</span><span>&nbsp;Our Platform.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; b. Preference Cookies. We use Preference Cookies to remember Your preferences and various settings.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; c. Security Cookies. We use Security Cookies for security purposes.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <strong
          ><span><span>3. How We Collect Information About You</span></span></strong
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>We use different methods to collect Your information, including through:</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>3.1 Direct interactions</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>&nbsp; a. With You when You provide it to us, for example, by filling in forms or corresponding with Us by phone, email, or otherwise.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>3.2 Automated technologies or interactions</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>&nbsp; a. As You navigate through the Platform. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>3.3 Third parties or publicly available sources</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>&nbsp; a. For example, Our business or advertising partners.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <strong
          ><span><span>4. Information You Provide to Us</span></span></strong
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>The information We collect directly from You on or through Our Platform may include:</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>4.1</span><span>&nbsp;Information that You provide by filling in forms on Our Platform</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>&nbsp; a. Interacting with the Platform&rsquo;s functionalities, using Our Platform to&nbsp;</span><span>facilitate</span><span>&nbsp;consultation with a Specialist or a Primary Care Provider, uploading to the Platform, posting any material, making payments to Our Platform services, or requesting further services. We may also ask You for information when You enter a conference or promotion sponsored by us, and when You report a problem to Us in relation to the Platform.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>4.2</span><span>&nbsp;Records and copies of Your correspondence (including email addresses)</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>&nbsp; a. If You contact Us.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <strong
          ><span><span>5</span></span></strong
        ><span>. Use of Your Information</span><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>5</span><span>.1 Virtual Hallway uses the collected data for various purposes:</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; a. To provide Our medical consultation management services on the Platform and&nbsp;</span><span>maintain</span><span>&nbsp;the Platform.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; b. To&nbsp;</span><span>notify You</span><span>&nbsp;about changes to the Platform.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; c. To allow You to&nbsp;</span><span>participate</span><span>&nbsp;in interactive features of Our Platform when You choose to do so.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; d. To provide customer care and support.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; e. To process payments and medical bills on behalf of Your business.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; f. To receive payments from You.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; g. To provide analysis or valuable information so that We can improve the Platform.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; h. To&nbsp;</span><span>monitor</span><span>&nbsp;the usage of the Platform.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span style="border-bottom: 1px solid transparent">i</span><span>. To detect, prevent and address technical issues.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; j. For any other purpose with Your consent.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>5.2 We may also use Your information to contact You about Our own and&nbsp;</span><span style="border-bottom: 1px solid transparent">third-parties</span><span>&apos; goods and services that may be of interest to You, as&nbsp;</span><span>permitted</span><span>&nbsp;by law. If You do not want Us to use Your information in this way, please e-mail Us at info@virtualhallway.ca. For more information, see Your Choices Section below.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>5.3 We will only share personal health information to a third-party entity and any other person&nbsp;</span><span>in accordance with</span><span>&nbsp;applicable privacy and security laws and with the terms and conditions of the EULA.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>5.4 We may use the information We have collected from You to enable Us to display advertisements to Our advertisers&apos; target audiences. Even though We do not&nbsp;</span><span>disclose</span><span>&nbsp;Your personal information for these purposes without Your consent, if You click on or otherwise interact with an advertisement on the Website, the advertiser may collect information about You, in which We do not have control. For example, the advertiser may&nbsp;</span><span>identify</span><span>&nbsp;You as their targeted audience.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>6. Data Processing and Transfer</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>6.1 Please note that We store and process Your personal information, including all Personal Data, in Canada. If You are&nbsp;</span><span>located</span><span>&nbsp;outside Canada and choose to provide information to us,&nbsp;</span><span style="border-bottom: 1px solid transparent">Your</span><span>&nbsp;consent to this Policy followed by Your submission of such information&nbsp;</span><span>represents</span><span>&nbsp;Your agreement to that transfer.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>6.2 We may transfer personal information that We collect or that You provide as described in this Policy to contractors, Our Service Providers (defined hereafter), and other third parties We use to support Our business (such as analytics and search engine providers that assist Us with Website improvement and optimization) and who are contractually obligated to keep personal information confidential, use it only for the purposes for which We disclose it to them, and to process the personal information with the same standards set out in this Policy.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <strong
          ><span><span>7. Disclosure of Your Information</span></span></strong
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>7.1 Virtual Hallway may&nbsp;</span><span>disclose</span><span>&nbsp;aggregated information about Our users, and information that does not&nbsp;</span><span>identify</span><span>&nbsp;any individual, without restriction.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>7.2 Virtual Hallway may&nbsp;</span><span>disclose</span><span>&nbsp;personally identifiable information that We&nbsp;</span><span style="border-bottom: 1px solid transparent">collect</span><span>&nbsp;or You provide in the good faith belief that such action is necessary:</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; a. To&nbsp;</span><span>comply with</span><span>&nbsp;Our legal obligations, including to respond to any government or regulatory request,&nbsp;</span><span>in accordance with</span><span>&nbsp;applicable law.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; b. To provide services of this Platform&nbsp;</span><span>in accordance with</span><span>&nbsp;the EULA, such as&nbsp;</span><span>facilitating</span><span>&nbsp;the consultation between the Primary Care Provider and the Specialist and&nbsp;</span><span>facilitating</span><span>&nbsp;medical billing between You and Billing Agent.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; c. To fulfill the purpose for which You provide it.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; d. To enforce or apply Our terms and conditions and other agreements, including for billing and collection purposes.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; e. In accordance with applicable law, to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or&nbsp;</span><span>all of</span><span>&nbsp;Virtual Hallway&rsquo;s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Virtual Hallway is among the assets transferred.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; f. To protect and defend the rights or property of Virtual Hallway.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; g. To prevent or investigate&nbsp;</span><span>possible wrongdoing</span><span>&nbsp;in connection with Our services.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span>&nbsp; h. To protect the personal safety of users of the Platform or the public.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
    <div>
      <p><span></span><span>&nbsp;</span></p>
    </div>
    <div>
      <p>
        <span><span style="border-bottom: 1px solid transparent">i</span><span>. To protect against Our legal liability.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <strong
          ><span><span>8. Security of Data</span></span></strong
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>8.1 The security of your personal information is&nbsp;</span><span>very important</span><span>&nbsp;to us. We use commercially acceptable means and industrial standard measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. We store all information you provide to us behind firewalls on secure servers hosted by our Service Provider. Any payment transactions and personal information You provided on or through the Platform will be encrypted using SSL technology.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>8.2 The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Platform, you&nbsp;</span><span>are responsible for</span><span>&nbsp;keeping this password confidential. We ask you not to share your password with anyone.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>8.3 Unfortunately, the transmission of information via the Internet or method of electronic storage is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information&nbsp;</span><span>transmitted to our&nbsp;</span><span style="border-bottom: 1px solid transparent">Website</span><span>. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <strong
          ><span><span>9. Retention of Data</span></span></strong
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>9.1 Except as otherwise&nbsp;</span><span>permitted</span><span>&nbsp;or required by applicable law or regulation,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;will only&nbsp;</span><span>retain</span><span>&nbsp;Your personal information and Patient personal health information for as long as necessary to fulfill the purposes We collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. Under some circumstances,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;may anonymize Your personal information so that it can no longer be associated with You. We reserve the right to use such anonymous and de-identified data for any legitimate business purpose without further notice to You or Your consent.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>9.2 Please refer to Our EULA as it sets forth specific rules&nbsp;</span><span style="border-bottom: 1px solid transparent">with regard to</span><span>&nbsp;the retention of&nbsp;</span><span style="border-bottom: 1px solid transparent">Patient</span><span>&nbsp;personal health information.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <strong
          ><span><span>10. Your Choices</span></span></strong
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>We strive to provide You with choices&nbsp;</span><span>regarding</span><span>&nbsp;the personal information You provide to Us. We have created mechanisms to provide You with the following control over Your information:</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>10.1 Tracking Technologies and Advertising</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>&nbsp; a. You can set Your browser to refuse all or some browser cookies, or to alert You when cookies are being sent. If You disable or refuse cookies, please note that some parts of the Platform may not be accessible or may not function properly.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>10.2 Promotional Offers from Us</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>&nbsp; a. If You have opted in to receive certain emails from Us but no longer wish to have Your contact information used by the Company to promote Our own or third parties&apos; products or services,&nbsp;</span><span style="border-bottom: 1px solid transparent">You</span><span>&nbsp;can opt-out by sending Us an email&nbsp;</span><span>stating</span><span>&nbsp;Your request to info@virtualhallway.ca. If we have sent You a promotional email,&nbsp;</span><span style="border-bottom: 1px solid transparent">You</span><span>&nbsp;may unsubscribe by clicking the unsubscribe link we have included in the email or change Your preferences in Your account profile.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>10.3 Third Party and Internet-based Advertising</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>&nbsp; a. We will not provide Your personal information to third parties, except for the purpose to provide Our services to You and otherwise&nbsp;</span><span>states</span><span>&nbsp;in the EULA or&nbsp;</span><span>herein</span><span>. However, we cannot control third parties&apos; collection or use of Your information to serve interest-based advertising. However, these third parties may provide You with ways to choose not to&nbsp;</span><span>have Your information collected or used in this way. You can opt out of several third-party ad servers&apos; and networks&apos; cookies simultaneously by using an opt-out tool created by the Digital Advertising Alliance of Canada. You can also access these websites to learn more about online behavioural advertising and how to stop websites from placing cookies on Your device. Opting out of a network does not mean You will no longer receive online advertising. It does mean that the network from which You opted out will no longer deliver ads tailored to Your web preferences and usage patterns.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <strong
          ><span><span>11. Accessing and Correcting Your Personal Information</span></span></strong
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>11.1 By law You have the right to request access to and to correct the personal information that we hold about you. You may review and change some of the personal information by logging into the Platform through the Website and visiting Your account profile page. Please keep Us informed if&nbsp;</span><span style="border-bottom: 1px solid transparent">Your</span><span>&nbsp;personal information changes. For any personal information that You cannot change by visiting Your account profile page,&nbsp;</span><span style="border-bottom: 1px solid transparent">You</span><span>&nbsp;may send Us an email at privacy@virtualhallway.ca to request access to, correct, or&nbsp;</span><span>delete</span><span>&nbsp;any personal information that You have provided to Us.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>11.2 Subject to applicable privacy law, a Patient may request access to their personal information and personal health information hosted by Us by sending Us an email at privacy@virtualhallway.ca to request access to, correct, or&nbsp;</span><span>delete</span><span>&nbsp;any personal information that You have provided to Us.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>11.3 For either situation in paragraph (a) or (b) above,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;may not accommodate a request to change information if We believe the change would violate any law or legal requirement or cause the information to be incorrect.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>11.4 In certain circumstances We may need to&nbsp;</span><span>delete</span><span>&nbsp;Your account to&nbsp;</span><span>delete</span><span>&nbsp;Your personal information. In such cases,&nbsp;</span><span style="border-bottom: 1px solid transparent">You</span><span>&nbsp;consent to Us&nbsp;</span><span>deleting</span><span>&nbsp;Your account for this purpose.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>11.5 We may request specific information from the person requesting access to their personal information to help Us confirm their identity and their right to access, and to provide You with the personal information that We hold about them (or their Patient) or make their requested changes. If We cannot provide You with access to the personal information,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;will inform them of the reasons why, subject to any legal or regulatory restrictions. We will provide access to their personal information, subject to exceptions set out in applicable privacy legislation.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>11.6 If any person is concerned about Our response or would like to correct the information provided, the person may contact Our Privacy Officer at privacy@virtualhallway.ca.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>12. Withdraw Consent</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>12.1 Where a person has provided consent to the collection, use, and transfer of the person&rsquo;s personal information, the person may have the legal right to withdraw consent under certain circumstances. To withdraw consent, if applicable, contact Us at privacy@virtualhallway.ca. Please note that if the person withdraws consent,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;may not be able to provide the person with a particular product or service. We will explain the impact to the person at the time to help You with Your decision.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>13. Third Party Providers</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>13.1 From time to time,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;may engage with or employ third-party entities and individuals (&ldquo;Service Providers&rdquo;) for&nbsp;</span><span>maintaining</span><span>&nbsp;functionalities of and making changes to the Platform, to host information and data of</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>&nbsp;the Platform, to process payment on behalf of You, or to collect your payment of subscription fees of the Platform.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>13.2 Service Providers have access to personal information&nbsp;</span><span>submitted</span><span>&nbsp;by You or collected by Us only to perform these tasks on Our behalf and are&nbsp;</span><span>obligated</span><span>&nbsp;under contractual arrangements not to&nbsp;</span><span>disclose</span><span>&nbsp;or use it for any other purpose and to process the personal information with the same standards set out in this Policy.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>13.3 You acknowledge that while Virtual Hallway strives to protect privacy and security of personal information You&nbsp;</span><span>submit</span><span>&nbsp;or We collect,&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;cannot guarantee our Service Providers will adhere to the same level of protection and security.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>13.4 You further acknowledge that, from time to time, Service Providers may also access or process de-identified Personal Information to&nbsp;</span><span>assist</span><span>&nbsp;Us in analyzing how Our Website is used.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <strong
          ><span><span>14. Links to Other Sites</span></span></strong
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>The Platform may include plug-ins, services, social networks, applications, or links to third-party websites. Clicking on those links or enabling those connections may allow the third party to collect or share data about You. If You follow a link to a third-party website or engage a third-party plug-in, please note that these third parties have their own privacy policies, and We do not accept any responsibility or liability for the practices of third-party websites or their policies. We do not control these third-party websites or applications, and We encourage You to read the privacy policy of every website or application You visit.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <strong
          ><span><span>15. Children&rsquo;s Privacy</span></span></strong
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>15.1 You&nbsp;</span><span>represent</span><span>&nbsp;and warrant that You are over the majority age of your&nbsp;</span><span>jurisdiction</span><span>&nbsp;or age of 19 (whichever is higher) when You access or use the Platform. Our Platform&nbsp;</span><span>does not address or intend to address any User under the age of&nbsp;</span><span style="border-bottom: 1px solid transparent">the majority of</span><span>&nbsp;Your&nbsp;</span><span>jurisdiction</span><span>; or any User under 19. We do not knowingly collect personal information from such user.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>15.2 We collect personal information, including personal health information, of a child Patient&nbsp;</span><span style="border-bottom: 1px solid transparent">under age</span><span>&nbsp;of 13, only if the child&rsquo;s parent(s) or legal guardian(s) have provided informed consent to the Primary Care Provider for the child&rsquo;s personal information to be stored, processed, or transferred through the Platform. If We learn&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;have collected or received personal information from a Patient who is a child under the age of 13 without verification of an informed parental consent, we will&nbsp;</span><span>delete</span><span>&nbsp;that information. If You believe&nbsp;</span><span style="border-bottom: 1px solid transparent">We</span><span>&nbsp;might have any information from or about a child under the age of 13, please contact Virtual Hallway at privacy@virtualhallway.ca.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <strong
          ><span><span>16. Changes to This Policy</span></span></strong
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>We may update Our Policy from time to time. We will&nbsp;</span><span>notify You</span><span>&nbsp;of any changes by posting the new Policy on this page. We will let You know via email and/or a prominent notice on Our Platform, prior to the change becoming effective and update the &ldquo;Effective Date&rdquo; at the top of this Policy. You are&nbsp;</span><span>advised to review</span><span>&nbsp;this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <strong
          ><span><span>Contact Us</span></span></strong
        ><span>&nbsp;</span>
      </p>
    </div>

    <div>
      <p>
        <span><span>If You have any questions about this Platform Privacy Policy, please contact Us by email: privacy@virtualhallway.ca.</span></span
        ><span>&nbsp;</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
