<template>
  <div :class="animation" class="w-full max-w-lg p-8 tile">
    <h1 class="text-xl font-bold text-left">What is your billing type?</h1>
    <h4 class="mb-6 text-sm text-left text-gray-600"></h4>
    <div class="mb-8">
      <div v-for="(providerType, index) in providerTypeOptions" :key="index" @click="setProviderType(providerType)" class="select-button border-hover--blue">
        <span>{{ providerType.name }}</span
        ><i class="pi pi-caret-right" />
      </div>
    </div>
    <form @submit.prevent="setOtherProviderType" class="w-full mb-5" v-if="user.provider_type === 'Other'">
      <div class="text-left">
        <label for="other_specialty" class="auth-input">Enter Your Provider Type If Not Listed Above</label>
        <InputText type="text" name="other_provider_type" id="other_provider_type" v-model="user.other_provider_type" required class="w-full auth-input" maxLength="255" />
      </div>
    </form>
    <div class="flex flex-row items-center justify-left align-center" :class="user.provider_type !== 'Other' ? 'justify-left' : 'justify-between'">
      <Button label="Previous" @click="goToPrevious" iconPos="left" class="p-button-text p-buttom-sm p-button-secondary" icon="pi pi-caret-left" />
      <Button @click="goNext" label="Continue" id="next-button" class="p-button p-buttom-sm p-button-text" iconPos="right" icon="pi pi-caret-right" v-if="user.provider_type === 'Other'" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

export default {
  components: {
    Button,
    InputText,
  },
  props: ['loggedInUser'],
  emits: ['goToNext', 'goToPrevious'],

  data() {
    return {
      animation: 'animate-in',
      user: this.loggedInUser,
      providerTypeOptions: [{ name: 'Fee For Service (FFS)', value: 'FFS' }],
      providerTypeValue: null,
    };
  },
  computed: {
    ...mapGetters(['allProvinces', 'hasProvinceOfPractice']),
  },
  methods: {
    ...mapActions(['updateUserAccountInfo']),
    setProviderType(providerType) {
      this.user.provider_type = providerType.value;
      if (this.user.provider_type !== 'Other') {
        this.user.other_provider_type = null;
        if (providerType.name === 'FHG / CCM') {
          this.user.other_provider_type = 'FHG / CCM';
        }
        if (this.user.provider_type == 'FFS' && this.user?.practice_province?.billing_opt_in) {
          this.$store.commit('setRequiresToUploadBillingArrangement', true);
        } else {
          this.$store.commit('setRequiresToUploadBillingArrangement', false);
        }
        this.$emit('goToNext', this.user);
      }
    },
    goNext() {
      this.$emit('goToNext', this.user);
    },
    goToPrevious() {
      this.$emit('goToPrevious', this.user);
    },
  },
  created() {
    if (this.hasProvinceOfPractice(this.loggedInUser, ['ON'])) {
      this.providerTypeOptions.push({ name: 'FHG / CCM', value: 'FFS' });
    } else if (this.hasProvinceOfPractice(this.loggedInUser, ['NS'])) {
      this.providerTypeOptions.push({ name: 'LFM', value: 'LFM' });
      this.providerTypeOptions.push({ name: 'Salaried APP/(C/AFP)', value: 'APP' });
    } else if (this.hasProvinceOfPractice(this.loggedInUser, ['PE', 'NL'])) {
      this.providerTypeOptions.push({ name: 'Salaried APP/(C/AFP)', value: 'APP' });
    }
    this.providerTypeOptions.push({ name: 'Other', value: 'Other' });
  },
};
</script>

<style></style>
