<template>
  <div class="flex flex-col items-center w-full">
    <div class="w-full max-w-5xl p-6 mb-6 text-left">
      <div class="flex flex-col md:flex-row">
        <div class="flex flex-col explicit-width">
          <img src="@/assets/DrDoronAlmagor.png" class="object-cover w-24 h-24 mb-4 mr-6 rounded-xl md:mb-0" />
          <div class="flex flex-col mb-4 mr-8 md:mb-0">
            <h2 class="mb-2 text-xl font-bold">Speaker</h2>
            <div class="flex flex-row items-center">
              <p class="mr-2 text-sm">Dr. Doron Almagor</p>
            </div>
            <p class="text-sm">Psychiatry</p>
          </div>
        </div>
        <p class="mr-2 text-sm">Dr. Doron Almagor (he/him) is a Child, Adolescent, and Adult Psychiatrist with specialty training in Psychiatry from the University of Toronto. He is recognized for his expertise in the diagnosis and treatment of ADHD and related conditions such as anxiety and depression. Dr. Almagor is a former Chair of CADDRA (Canadian ADHD Resource Alliance) and a past President of the Ontario Psychiatric Association (OPA). He regularly lectures internationally and provides training to medical residents at the University of Toronto in ADHD management. As the Director of the Possibilities Clinic in Toronto, Dr. Almagor leads a multidisciplinary team of experts in psychiatry, psychology, social work, pediatrics, speech-language pathology, and occupational therapy. The clinic offers in-person and telemedicine services across Ontario, providing comprehensive care for children, adolescents, and adults with ADHD. Dr. Almagor is committed to advancing evidence-based practices and fostering collaborative care to achieve meaningful outcomes for patients and their families.</p>
      </div>
    </div>

    <div class="w-full max-w-5xl p-6 mb-10 text-left">
      <div class="flex flex-col md:flex-row">
        <div class="flex flex-col explicit-width">
          <img src="@/assets/DrGregMattingly.jpeg" class="object-cover w-24 h-24 mb-4 mr-6 rounded-xl md:mb-0" />
          <div class="flex flex-col mb-4 mr-8 md:mb-0">
            <h2 class="mb-2 text-xl font-bold">Speaker</h2>
            <div class="flex flex-row items-center">
              <p class="mr-2 text-sm">Dr. Greg Mattingly</p>
            </div>
            <p class="text-sm">Psychiatry</p>
          </div>
        </div>
        <p class="mr-2 text-sm">Gregory W. Mattingly, MD, is an Associate Clinical Professor of Psychiatry and has been a Psychopharmacology Instructor for over twenty years at The Washington University School of Medicine in St. Louis, Missouri. He earned his medical degree and completed his residency at Washington University, where he received a Fulbright Scholarship. Dr. Mattingly has been a principal investigator in more than 400 clinical trials and his research has been published in numerous national and international journals. In addition to his clinical and research practice, Dr. Mattingly worked as a consultant and evaluator for the National Football League and Major League Baseball. Dr. Mattingly is also the President for the American Professional Society for ADHD and Related Disorders, serves on the Scientific Program Committee for the World Federation for ADHD and is Co-Chair for the US Psych Congress.</p>
      </div>
    </div>
  </div>
</template>

<script></script>
<style scoped>
.explicit-width {
  width: 100% !important;
  min-width: 170px !important;
}
</style>
