<template lang="">
  <a @click="openDialog" class="text-xs underline text-secondary" href="#">Platform Privacy Policy</a>
  <Dialog header="Virtual Hallway Platform Privacy Policy" :breakpoints="{ '960px': '50vw', '640px': '90vw' }" style="max-width: 800px; width: 100%; padding-bottom: 48px" v-model:visible="show" :modal="true" :dismissableMask="true" :draggable="false" :closable="true">
    <PlatformPrivacyPolicy />
    <template #footer>
      <Button label="Close" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="closeDialog()" />
    </template>
  </Dialog>
</template>
<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import PlatformPrivacyPolicy from '@/views/public/privacy/PlatformPrivacyPolicy.vue';

export default {
  components: {
    Button,
    Dialog,
    PlatformPrivacyPolicy,
  },
  data() {
    return {
      isLoading: false,
      show: false,
    };
  },
  methods: {
    openDialog() {
      this.show = true;
    },
    closeDialog() {
      this.show = false;
    },
  },
};
</script>
<style lang=""></style>
