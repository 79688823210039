<template>
  <h1 class="text-xl font-bold text-center">Let's Setup Your Account</h1>
  <div :class="animation" class="flex flex-col items-end w-full max-w-lg mt-6 tile">
    <template v-if="!emailSuccessfullyVerified">
      <div class="flex flex-col items-center w-full px-8 mt-5 text-start">
        <Message :closable="false" class="text-start" v-if="!requestedVerificationCode">
          You have registered with a relayed email address ({{ user.email }}). To ensure that you gain and maintain access to all of the Virtual Hallway features, please enter a contact email address.
          <br />
          <br />
          Emails sent from our application such as, consult reminders, CME lecture invites and your account updates will be sent to your contact email. You can control what emails you want to receive later on.
        </Message>
        <div class="w-full text-start">
          <label for="email" class="text-sm font-display">Contact Email Address</label>
          <InputText type="email" name="email" id="contactEmailInput" v-model="contactEmail" required class="w-full mt-1" maxLength="255" />
        </div>
      </div>
      <div class="flex flex-col w-full px-8 animated fade-in-up" v-if="requestedVerificationCode && !isLoading">
        <InputText type="text" v-model="contactEmailVerificationCode" required class="w-full mt-3" maxLength="255" placeholder="Enter the code from your email." id="contactEmailVerificationCodeInput" />
        <Message severity="success" :closable="false" class="text-start" v-if="!errorMsg"> We have sent a verification code to your email, please enter the code above. </Message>
        <Message severity="error" :closable="false" class="text-start" v-if="errorMsg"> {{ errorMsg }} </Message>
      </div>
      <div class="flex flex-row items-center w-full px-8 my-6" :class="{ 'justify-between': requestedVerificationCode, 'justify-end': !requestedVerificationCode }" v-if="!isLoading">
        <Button label="Resend Code" class="p-button p-buttom-sm p-button-secondary p-button-outlined animated fade-in-up" iconPos="right" icon="pi pi-refresh" @click="submitRequestVerifyContactEmail()" :disabled="!contactEmail" v-if="requestedVerificationCode" />
        <div v-if="!requestedVerificationCode" v-tooltip="{ value: 'Please enter your contact email.', disabled: contactEmail }" class="text-end">
          <Button label="Verify Contact Email" class="p-button p-buttom-sm p-button-outlined" iconPos="right" icon="pi pi-check" @click="submitRequestVerifyContactEmail()" :disabled="!contactEmail" id="verifyContactEmailBtn" />
        </div>
        <Button label="Verify Code" class="p-button p-buttom-sm animated fade-in-up" iconPos="right" icon="pi pi-check" @click="submitVerifyContactEmail()" :disabled="!contactEmail" v-if="requestedVerificationCode" id="verfiyContactEmailCodeBtn" />
      </div>
      <ProgressSpinner v-if="isLoading" class="my-3" />
    </template>
    <div class="flex flex-col items-center w-full p-8 animated fade-in-up" v-else>
      <div class="p-3 border border-green-400 rounded-full animated fade-in-up">
        <i class="text-green-400 pi pi-check" style="font-size: 2.5rem"></i>
      </div>
      <div class="mt-5 font-display animated fade-in-up">Contact email successfully set.</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from 'primevue/button';

export default {
  components: {
    Button,
  },
  props: ['propsUser'],
  emits: ['contactEmailSuccessfullySet'],

  data() {
    return {
      animation: 'animate-in',
      user: null,
      contactEmail: null,
      contactEmailVerificationCode: null,
      requestedVerificationCode: false,
      emailSuccessfullyVerified: false,
      isLoading: false,
      errorMsg: null,
    };
  },
  computed: {
    ...mapGetters(['isGP', 'isSpecialist', 'allProvinces', 'loggedInUser']),
  },
  created() {
    this.user = this.propsUser;
    this.firstName = this.user.first_name;
    this.lastName = this.user.last_name;
  },
  methods: {
    ...mapActions(['requestContactEmailVerification', 'verifyOtpForContactEmailVerification']),
    submitRequestVerifyContactEmail() {
      this.isLoading = true;
      this.requestContactEmailVerification({
        contact_email: this.contactEmail,
      })
        .then(() => {
          this.requestedVerificationCode = true;
          this.errorMsg = null;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    submitVerifyContactEmail() {
      this.isLoading = true;
      this.verifyOtpForContactEmailVerification({
        code: this.contactEmailVerificationCode,
      })
        .then(() => {
          this.errorMsg = null;
          this.isLoading = false;
          this.user.email = this.loggedInUser.email;
          this.emailSuccessfullyVerified = true;
          setTimeout(() => {
            this.$emit('contactEmailSuccessfullySet', this.user);
          }, 3000);
        })
        .catch((err) => {
          if (err.data.code) {
            this.errorMsg = err.data.code;
          } else this.errorMsg = err.data.error;

          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.p-float-label label {
  color: rgb(152, 152, 152) !important;
}
</style>
