<template>
  <span class="text-xs cursor-pointer text-primary" @click="openCmeEligibility()">Find out here <i class="text-xs pi pi-external-link" style="font-size: 0.6rem" /></span>
  <Dialog header="Who Can Watch Virtual Hallway Lectures?" :breakpoints="{ '960px': '50vw', '640px': '90vw' }" style="width: 800px" v-model:visible="show" :modal="true" :dismissableMask="true" :draggable="false" :closable="true" id="cmeEligibilityDialog">
    <div class="p-2 text-sm text-left">
      <h2 class="text-lg font-semibold">Eligibility</h2>
      <p class="mb-4">Any healthcare practitioner or student in a healthcare field can sign up and watch Virtual Hallway lectures. Virtual Hallway consults is a clinical tool and is only eligible to licensed physicians and nurse practitioners in regions in which Virtual Hallway operates its consult platform. However, the lectures are open to professionals worldwide</p>
      <h2 class="text-lg font-semibold">Operational Regions</h2>
      <p class="mb-4">Our lecture series is available worldwide. If you are a licensed physician or nurse practitioner in Nova Scotia, Ontario and Prince Edward Island, your account will also allow you to use the Virtual Hallway consult platform to consult with peers in your region.</p>
      <h2 class="text-lg font-semibold">Cost</h2>
      <p>Joining Virtual Hallway is completely free. No hidden charges.</p>
    </div>
    <template #footer>
      <Button label="Close" icon="pi pi-times" class="mr-4 p-button-secondary p-button-outlined" @click="closeCmeEligibility()" />
    </template>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
export default {
  components: {
    Button,
    Dialog,
  },
  data() {
    return {
      isLoading: false,
      show: false,
    };
  },
  methods: {
    openCmeEligibility() {
      this.show = true;
    },
    closeCmeEligibility() {
      this.show = false;
    },
  },
};
</script>
<style></style>
