<template>
  <h1 class="flex flex-row items-center justify-between mb-3 text-xl font-bold">
    {{ selectedLanguage == 'Fr' ? 'Abonnez-vous pour recevoir les POEMs' : 'Subscribe To Receive POEMs' }}
    <div class="w-[110px]" v-if="!loggedInUser"><SelectButton v-model="currentSelectedLanguage" :options="['En', 'Fr']" aria-labelledby="basic" @change="onSelectedLanguageChange" /></div>
  </h1>
  <form method="post" @submit.prevent="onSubmit" class="w-full">
    <div class="py-2" v-if="!loggedInUser">
      <span class="p-float-label">
        <label for="subscribeToPoemsEmail">Email</label>
        <InputText type="email" name="subscribeToPoemsEmail" id="subscribeToPoemsEmail" v-model="email" required :class="['w-full']" maxLength="255" />
      </span>
    </div>
    <div v-else>
      <p class="w-full text-sm text-gray-700">Receive expert-reviewed summaries of the latest clinical research delivered to your inbox.</p>
    </div>

    <div class="mt-4">
      <Button type="submit" id="subscribeToPoemsSubmitButton" class="w-full text-center p-button-secondary" :label="selectedLanguage == 'Fr' ? 'S’abonner' : 'Subscribe'"></Button>
    </div>
    <div class="mt-6 text-sm text-center font-display" v-if="!loggedInUser">
      {{ selectedLanguage == 'Fr' ? 'Déjà abonné?' : 'Already Subscribed?' }}
      <span class="cursor-pointer text-primary" @click="$router.push(`/registration`)">
        {{ selectedLanguage == 'Fr' ? 'Créez un compte' : 'Create an Account' }}
      </span>
      {{ selectedLanguage == 'Fr' ? 'ou' : 'or' }}
      <span class="cursor-pointer text-primary" @click="redirectToLogin">
        {{ selectedLanguage == 'Fr' ? 'Connectez-vous' : 'Login' }}
      </span>
    </div>
  </form>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

import { mapGetters, mapActions } from 'vuex';
export default {
  props: ['selectedPoem', 'selectedLanguage'],
  emits: ['successfullySubscribedToPoems', 'onSelectedLanguageChange'],
  inject: ['mixpanel'],
  components: { Button, InputText },
  data() {
    return {
      email: null,
      currentSelectedLanguage: this.selectedLanguage,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    ...mapActions(['subscribeToKlaviyoList', 'updateNotificationSetting']),
    onSubmit() {
      const promises = [];
      if (this.loggedInUser) {
        let notificationSetting = this.loggedInUser.profile.notification_setting;
        Object.keys(notificationSetting).forEach((key) => {
          if (!Array.isArray(notificationSetting[key])) {
            notificationSetting[key] = JSON.parse(notificationSetting[key]);
          }
        });
        notificationSetting.poems = ['Email'];
        promises.push(this.updateNotificationSetting(notificationSetting));
      } else {
        let data = { email: this.email, list: 'Poems' };
        if (this.$route.params.connectCode) {
          data.connect_code = this.$route.params.connectCode;
        }
        promises.push(this.subscribeToKlaviyoList(data));
      }

      Promise.all(promises)
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: this.selectedLanguage === 'Fr' ? 'Abonné aux POEMs' : 'Subscribed To POEMs',
            life: 3000,
          });
          this.$emit('successfullySubscribedToPoems', { prefillEmail: this.email });
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    redirectToLogin() {
      if (this.selectedPoem) {
        this.$router.push(`/login?redirect=poem/${this.selectedPoem.id}`);
      } else {
        this.$router.push(`/login?redirect=poems`);
      }
    },
    onSelectedLanguageChange() {
      this.$emit('onSelectedLanguageChange', this.currentSelectedLanguage);
    },
  },
  created() {
    if (this.loggedInUser) {
      this.email = this.loggedInUser.email;
    }
  },
};
</script>

<style></style>
