<template>
  <div v-if="user">
    <div class="h-64 px-6 mx-auto overflow-y-scroll grid gap-5 grid-cols-4 md:grid-cols-6">
      <div v-for="avatarUrl in avatarUrls" :key="avatarUrl">
        <img v-bind:src="avatarUrl.file_url" v-tooltip.right="avatarUrl.file_name" :class="['rounded-full', 'h-16', 'w-16', 'object-cover', 'mx-auto', 'bg-white', 'hover-slide-up', 'mt-2', 'shadow-lg', { selected: avatarUrl === selectedAvatar }]" @click="selectAvatar(avatarUrl)" />
      </div>
    </div>
    <div class="flex justify-center mt-6">
      <Button label="Select Avatar" icon="pi pi-user" class="p-button p-button-secondary" :disabled="selectedAvatar === null" @click="selectAvatarOnClick" />
    </div>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#5e76ff" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Button from 'primevue/button';
import Loading from 'vue-loading-overlay';

export default {
  props: ['loggedInUser'],
  data() {
    return {
      user: this.loggedInUser,
      selectedAvatar: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['avatarUrls']),
  },
  methods: {
    ...mapActions(['updateUserAvatar', 'getAllAvatarUrls']),
    selectAvatarOnClick() {
      const data = { ...this.selectedAvatar, user_id: this.loggedInUser.id };
      this.updateUserAvatar(data)
        .then(() => {
          this.selectedAvatar = null;
          this.$toast.add({
            severity: 'success',
            summary: 'Account Updated',
            detail: 'Your profile picture has been successfully updated.',
            life: 5000,
          });
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    selectAvatar(avatarUrl) {
      this.selectedAvatar = avatarUrl;
    },
  },
  created() {
    this.getAllAvatarUrls()
      .then(() => {})
      .catch(() => {});
  },
  components: {
    Button,
    Loading,
  },
};
</script>

<style>
.hover-slide-up {
  transition: all 0.2s ease-in-out;
}
.hover-slide-up:hover {
  transform: translateY(-5px);
}
.selected {
  border: 3px solid #f7bd5d;
}
</style>
