<template>
  <div class="flex flex-col w-1/2 mx-auto h-96" id="notifyUserFromTextMessageParentDiv">
    <div class="flex flex-col justify-center p-12 my-auto bg-white rounded" v-if="showError">
      <div class="p-5">
        <i class="text-yellow-500 pi pi-exclamation-circle" style="font-size: 3rem"></i>
      </div>
      <div class="font-semibold">The link you have visited has either expired or does not exist. If the link has expired, you will receive another email with a new link shortly.</div>
    </div>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#5e76ff" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';

export default {
  data() {
    return {
      isLoading: false,
      showError: false,
    };
  },
  computed: {},
  components: {
    Loading,
  },
  created() {
    this.isLoading = true;
    this.verifyUserInviteCode({
      code: this.$route.params?.inviteCode,
    })
      .then((code) => {
        let meta = JSON.parse(code.meta_data);
        if (!meta) {
          this.isLoading = false;
          this.showError = true;
        } else if (meta.user_already_exists) {
          //TODO_Booking_Hallway: Handle if user is already logged in
          this.$router.push({ path: `/login`, query: { accept_invite: true, c: this.$route.params?.inviteCode, email: this.$route.query?.email } });
        } else {
          this.$router.push({ path: `/registration`, query: { accept_invite: true, c: this.$route.params?.inviteCode, email: this.$route.query?.email } });
        }
      })
      .catch(() => {
        this.isLoading = false;
        this.showError = true;
      });
  },
  methods: {
    ...mapActions(['verifyUserInviteCode']),
  },
};
</script>

<style scoped></style>
