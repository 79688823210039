import axios from 'axios';
import globals from '../../globals';

const state = {
  smartPaths: [],
  selectedSmartPath: null,
  smartPath: '',
  path: [],
  curr: '0',
  answerStack: [],
  smartPathTransactions: [],
  smartPathTransactionsPaginatorTotal: 0,
  smartPathTransactionsSelectedSmartPathName: '',
  transactionHistory: [],
  selectedTransaction: null,
  smartPathReferralForm: null,
  smartPathSpecialty: null,
  smartPathReferralTransactionId: null,
  selectedSmartPathReferralContributor: null,
  smartPathReferralSurveyFlag: null,
  availableContributors: [],
  selectedContributor: null,
  linkableSmartPaths: [],
  orgUnitUsersOptedIntoSmartPath: [],
};

const getters = {
  smartPaths: (state) => {
    return state.smartPaths;
  },
  selectedSmartPath: (state) => {
    return state.selectedSmartPath;
  },
  smartPath: (state) => {
    return state.smartPath;
  },
  path: (state) => {
    return state.path;
  },
  curr: (state) => {
    return state.curr;
  },
  answerStack: (state) => {
    return state.answerStack;
  },
  smartPathTransactions: (state) => {
    return state.smartPathTransactions;
  },
  smartPathTransactionsPaginatorTotal: (state) => {
    return state.smartPathTransactionsPaginatorTotal;
  },
  smartPathTransactionsSelectedSmartPathName: (state) => {
    return state.smartPathTransactionsSelectedSmartPathName;
  },
  transactionHistory: (state) => {
    return state.transactionHistory;
  },
  selectedTransaction: (state) => {
    return state.selectedTransaction;
  },
  smartPathReferralForm: (state) => {
    return state.smartPathReferralForm;
  },
  smartPathSpecialty: (state) => {
    return state.smartPathSpecialty;
  },
  smartPathReferralTransactionId: (state) => {
    return state.smartPathReferralTransactionId;
  },
  selectedSmartPathReferralContributor: (state) => {
    return state.selectedSmartPathReferralContributor;
  },
  smartPathReferralSurveyFlag: (state) => {
    return state.smartPathReferralSurveyFlag;
  },
  availableContributors: (state) => {
    return state.availableContributors;
  },
  selectedContributor: (state) => {
    return state.selectedContributor;
  },
  linkableSmartPaths: (state) => {
    return state.linkableSmartPaths;
  },
  orgUnitUsersOptedIntoSmartPath: (state) => {
    return state.orgUnitUsersOptedIntoSmartPath;
  },
};

const actions = {
  getSmartPath(context, smartPathId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getSmartPathUrl(smartPathId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedSmartPath', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSmartPathForSurvey(context, smartPathId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getSmartPathUrl(smartPathId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSmartPath', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSmartPathsOfUser(context, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getUsersSmartPathsUrl(userId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSmartPaths', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPublicSmartPaths(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.getUsersPublicSmartPathsUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSmartPaths', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // use this for creating and saving smartpaths
  saveSmartPath(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.saveSmartPathUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedSmartPath', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveSmartPathTransaction(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.saveSmartPathTransaction(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedTransaction', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSmartPathTransactions(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(data.url, data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSmartPathTransactionsSelectedSmartPathName', response.data.smart_path_name);
          context.commit('setSmartPathTransactionsPaginatorTotal', response.data.total);
          context.commit('setSmartPathTransactions', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadLabAccessForm(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.downloadLabAccessFormUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
          responseType: 'blob',
        })
        .then((response) => {
          let fileName = 'LabReq_' + data.id + '.pdf';
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: fileName })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSmartPathTransactionHistoryOfUser(context, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getSmartPathTransactionHistoryUrl(userId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setTransactionHistory', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSmartPathTransactionById(context, transactionId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getSmartPathTransactionUrl(transactionId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedTransaction', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateTransactionStatus(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.updateTransactionStatusUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedTransaction', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadSmartPathResult(context, transactionId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.downloadSmartPathResultUrl(), {
          params: {
            transaction_id: transactionId,
          },
          headers: context.rootGetters.setHeaderWithAuth,
          responseType: 'blob',
        })
        .then((response) => {
          let fileName = 'PathwayResult-' + transactionId + '.pdf';
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: fileName })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  faxSmartPathResult(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.faxSmartPathResultUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  recordLearnMore(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.recordLearnMoreUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  uploadSmartPathLabForm(context, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('id', data.id);
      formData.append('node_id', data.node_id);
      formData.append('lab_file', data.lab_file);

      axios
        .post(globals.APIs.uploadLabFormUrl(), formData, {
          headers: { ...context.rootGetters.setHeaderWithAuth, 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          context.commit('setSelectedSmartPath', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  uploadSmartPathForYourPatientFiles(context, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('id', data.id);
      formData.append('node_id', data.node_id);

      for (var i = 0; i < data.for_your_patient_files.length; i++) {
        formData.append('files[]', data.for_your_patient_files[i]);
      }

      axios
        .post(globals.APIs.uploadForYourPatientFilesUrl(), formData, {
          headers: { ...context.rootGetters.setHeaderWithAuth, 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          context.commit('setSelectedSmartPath', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  previewSmartPathLabForm(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.previewSmartPathLabFormUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
          responseType: 'blob',
        })
        .then((response) => {
          let fileName = data.id + '_' + data.node_id + '.pdf';
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: fileName })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadSmartPathForYourPatientFile(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          globals.APIs.downloadSmartPathForYourPatientFileUrl(),
          { file: data.file, stat_data: data.stat_data, smart_path_id: data.smart_path_id },
          {
            headers: context.rootGetters.setHeaderWithAuth,
            responseType: 'blob',
          }
        )
        .then((response) => {
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: data.filename })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeSmartPathLabForm(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.removeSmartPathLabFormUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedSmartPath', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeSmartPathForYourPatientFile(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.removeSmartPathForYourPatientFileUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedSmartPath', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  toggleTransactionSeen(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.toggleTransactionSeenUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAvailableContributors(context, smartPathId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getAvailableContributorsUrl(smartPathId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setAvailableContributors', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  attachContributors(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.attachContributorsUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedSmartPath', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  detachContributors(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.detachContributorsUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedSmartPath', response.data.data);
          // context.commit('setAvailableContributors', response.data.data.contributors);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  attachSmartPathOrgUnits(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.attachSmartPathOrgUnitsUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedSmartPath', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  detachSmartPathOrgUnit(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.detachSmartPathOrgUnitUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedSmartPath', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getLinkableSmartPaths(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.getLinkableSmartPathsUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setLinkableSmartPaths', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  linkSmartPathTransaction(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.linkSmartPathTransactionUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedTransaction', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveTransactionTriageMessage(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.saveTransactionTriageMessageUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedTransaction', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveTransactionMessageForTriage(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.saveTransactionMessageForTriageUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedTransaction', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOrgUnitUsersOptedIntoSmartPath(context, org_unit_head_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getOrgUnitUsersOptedIntoSmartPathUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: { org_unit_head_id: org_unit_head_id },
        })
        .then((response) => {
          context.commit('setOrgUnitUsersOptedIntoSmartPath', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setSmartPaths(state, data) {
    state.smartPaths = data;
  },
  setSelectedSmartPath(state, data) {
    state.selectedSmartPath = data;
  },
  setSmartPath(state, data) {
    state.smartPath = data;
    state.smartPath.smart_path = JSON.parse(data.smart_path);
  },
  setPath(state, data) {
    state.path = data;
  },
  setCurr(state, data) {
    state.curr = data;
  },
  pushAnswerStack(state, data) {
    state.answerStack.push(data);
  },
  popAnswerStack(state) {
    // for terminal nodes we push two things, so pop twice for them
    // do it up here to maintain the logic of the .length-1 below
    if (state.smartPath.smart_path[state.curr]['node_type'] == 'terminal') {
      state.answerStack.pop();
    }

    state.curr = state.answerStack[state.answerStack.length - 1].id;
    state.answerStack.pop();
  },
  popAllAfterIndexAnswerStack(state, data, question, value) {
    let index = 0;
    while (index < state.answerStack.length && state.answerStack[index].id != data) {
      index++;
    }
    if (index != state.answerStack.length) {
      state.answerStack[index].question = question;
      state.answerStack[index].value = value;
      state.answerStack.length = index;
      state.curr = data;
    }
  },
  resetAnswerStack(state) {
    state.answerStack = [];
  },
  resetCurr(state) {
    state.curr = '0';
  },
  setAnswerStack(state, data) {
    state.answerStack = data;
  },
  setSmartPathTransactionsPaginatorTotal(state, data) {
    state.smartPathTransactionsPaginatorTotal = data;
  },
  setSmartPathTransactions(state, data) {
    state.smartPathTransactions = data;
  },
  setTransactionHistory(state, data) {
    state.transactionHistory = data;
  },
  setSelectedTransaction(state, data) {
    state.selectedTransaction = data;
  },
  setSmartPathReferralForm(state, data) {
    state.smartPathReferralForm = data;
  },
  setSmartPathSpecialty(state, data) {
    state.smartPathSpecialty = data;
  },
  setSmartPathReferralTransactionId(state, data) {
    state.smartPathReferralTransactionId = data;
  },
  setSelectedSmartPathReferralContributor(state, data) {
    state.selectedSmartPathReferralContributor = data;
  },
  setSmartPathTransactionsSelectedSmartPathName(state, data) {
    state.smartPathTransactionsSelectedSmartPathName = data;
  },
  setSmartPathReferralSurveyFlag(state, data) {
    state.smartPathReferralSurveyFlag = data;
  },
  setAvailableContributors(state, data) {
    state.availableContributors = data;
  },
  setSelectedContributor(state, data) {
    state.selectedContributor = data;
  },
  setLinkableSmartPaths(state, data) {
    state.linkableSmartPaths = data;
  },
  setOrgUnitUsersOptedIntoSmartPath(state, data) {
    state.orgUnitUsersOptedIntoSmartPath = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
