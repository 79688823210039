<template>
  <div class="relative flex flex-col justify-between w-full bg-white shadow-md cursor-pointer animated fade-in-up animation-delay rounded-xl animation-delay-stagger" :style="'--index: ' + index" @click="goToLectureId(lecture.id)" :aria-label="lecture.lecture_title">
    <div class="relative h-64 overflow-hidden v-r-top video-background" :style="{ backgroundImage: `url(${lecture.lecture_image_location})` }">
      <div v-if="lecture.cme_credit_available > 0" class="flex flex-row items-center px-3 py-2 text-xs font-bold cme-label" :class="{ 'bg-secondary': checkIfUserHasClaimedCredit(lecture.id) }">
        <div v-if="checkIfUserHasClaimedCredit(lecture.id)" class="flex flex-row items-center">
          <i class="mr-2 pi pi-check" style="font-size: 0.8rem" />
          <h1 class="mr-1 font-bold">You have claimed this credit</h1>
        </div>
        <div v-if="!checkIfUserHasClaimedCredit(lecture)" class="flex flex-row items-center">
          <i class="pi fas fa-award" style="font-size: 0.8rem" />
        </div>
      </div>
      <i v-if="this.loggedInUser" @click="toggleLectureBookmarkOnClick(lecture)" v-on:click.stop class="absolute text-white cursor-pointer bottom-4 right-4 pi" :class="[{ 'pi-bookmark': lecture.bookmarked == false }, { 'pi-bookmark-fill': lecture.bookmarked == true }]" style="font-size: 1.7rem" v-tooltip.left="'Add/remove from your bookmarks'"></i>
    </div>
    <div class="flex flex-col justify-between p-4 text-left text-gray-600 rounded-b-xl grow">
      <div class="flex flex-col font-bold">
        <div class="pb-2 mb-2 border-b text-md font-display">{{ lecture.lecture_title }}</div>
        <div class="flex flex-row items-center justify-between w-full border-round">
          <div class="flex flex-col text-sm">
            <h3 class="mt-1">Dr. {{ lecture.presenter_name }} • {{ lecture.presenter_specialty }}</h3>
            <h4 v-if="buttonText == 'Register'" class="mt-1">{{ formatReadableDateTimeWithZone(lecture.lecture_datetime) }}</h4>
          </div>
        </div>
      </div>
      <Button class="get-started-button w-full p-button-sm !mt-3" :class="{ 'p-button-outlined': buttonText == 'Watch' }" :label="buttonText" :icon="buttonText == 'Watch' ? 'pi pi-play-circle' : ''" iconPos="left" style="display: block" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  props: ['lecture', 'index', 'buttonText'],
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    ...mapActions(['toggleLectureBookmark']),
    toggleLectureBookmarkOnClick(lecture) {
      this.toggleLectureBookmark({ lecture_id: this.lecture.id, user_id: this.loggedInUser.id });
      lecture.bookmarked = !lecture.bookmarked;
      if (this.lecture.bookmarked) {
        this.$toast.add({
          severity: 'success',
          summary: 'Lecture Bookmarked',
          life: 8000,
        });
      }
    },
    checkIfUserHasClaimedCredit(lecture) {
      return lecture?.cme_credits?.length > 0;
    },
    goToLectureId(id) {
      this.$router.push('/lecture/' + id);
    },
  },
};
</script>
