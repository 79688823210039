<template>
  <div class="pt-12 mx-0 modal md:mx-2" v-if="show">
    <div class="modal__backdrop" @click="closeModal()" />
    <transition name="pop" appear>
      <div class="modal__dialog animated fade-in-up">
        <div class="modal__header">
          <slot name="header" />
        </div>

        <div class="modal__body">
          <slot name="body" />
        </div>

        <div class="modal__footer">
          <slot name="footer" class="rounded-b" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  emits: ['closeModal'],
  name: 'Modal',
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      // document.querySelector('body').classList.remove('overflow-hidden');
      this.$emit('closeModal', { show: this.show });
    },
    openModal() {
      this.show = true;
      // document.querySelector('body').classList.add('overflow-hidden');
    },
  },
};
</script>

<style scoped>
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}
.modal__backdrop {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  margin-right: -15px;
  margin-left: -15px;
}
.modal__dialog {
  background-color: var(--vh-white);
  position: relative;
  width: fit-content;
  margin: 25px auto;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  z-index: 1001;
  /* @media screen and (max-width: 992px) {
    width: 90%;
  } */
}
.modal__close {
  width: 30px;
  height: 30px;
}
.modal__header {
  /* padding: 20px 20px 10px; */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.model__body {
  padding: 10px 20px 10px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: fit-content;
}
.model__footer {
  padding: 10px 20px 20px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.pop-enter-active,
.pop-leave-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(0.3) translateY(-50%);
}
</style>
