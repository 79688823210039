import axios from 'axios';
import globals from '../../globals';

const state = {
  connectProgramInviteCode: null,
  rewardPointsCount: null,
  claimedRewards: null,
  invites: null,
};

const getters = {
  connectProgramInviteCode: (state) => {
    return state.connectProgramInviteCode;
  },
  invitedEmails: (state) => {
    return state.invitedEmails;
  },
  acceptedEmails: (state) => {
    return state.acceptedEmails;
  },
  claimedRewards: (state) => {
    return state.claimedRewards;
  },
  invites: (state) => {
    return state.invites;
  },
};

const actions = {
  getConnectProgramInviteCode(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getConnectProgramInviteCodeUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setConnectProgramInviteCode', response.data.code);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  sendConnectProgramEmailInvites(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.sendConnectProgramEmailInvitesUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setInvitedEmails', response.data.invitedEmails);
          context.commit('setAcceptedEmails', response.data.acceptedEmails);
          context.commit('setClaimedRewards', response.data.claimedRewards);
          context.commit('setInvites');
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  subscribeToPoemsFromInvite(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.subscribeToPoemsFromInviteUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getRewardPointsByUser(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getRewardPointsByUserUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setInvitedEmails', response.data.invitedEmails);
          context.commit('setAcceptedEmails', response.data.acceptedEmails);
          context.commit('setClaimedRewards', response.data.claimedRewards);
          context.commit('setInvites');
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  claimReward(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.claimRewardUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setClaimedRewards', response.data.claimedRewards);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setConnectProgramInviteCode(state, data) {
    state.connectProgramInviteCode = data;
  },
  setInvitedEmails(state, data) {
    state.invitedEmails = data;
  },
  setAcceptedEmails(state, data) {
    state.acceptedEmails = data;
  },
  setClaimedRewards(state, data) {
    state.claimedRewards = data;
  },
  setInvites(state) {
    let all = new Set([...state.invitedEmails, ...state.acceptedEmails]);
    let invs = [];
    all.forEach((email) => {
      let status = 'Pending';
      if (state.acceptedEmails.includes(email)) {
        status = 'Accepted';
      }
      invs.push({ email: email, status: status });
    });
    // can't push values to state variables... >:(
    state.invites = invs;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
