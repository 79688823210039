<template>
  <div class="flex flex-col justify-center w-full text-center align-center" aria-label="registrationFormDiv">
    <h2 class="mb-2 text-xs font-medium text-gray-500" v-if="!customHeadline">{{ selectedLanguage === 'Fr' ? 'Bienvenue dans le Virtual Hallway' : 'Welcome to Virtual Hallway' }}</h2>
    <h1 v-if="!customHeadline" class="mb-6 text-xl font-bold">
      {{ selectedLanguage === 'Fr' ? 'Inscrivez-vous pour votre compte gratuit' : 'Sign up for your free account' }}
    </h1>
    <h1 v-else-if="customHeadline == 'poemsHeadline'" class="hidden"></h1>
    <h1 v-else class="mb-2 text-xl font-bold">{{ customHeadline }}</h1>
    <h2 v-if="background_role == 'cme'" class="mb-8 text-xs font-medium text-gray-500">
      {{ selectedLanguage === 'Fr' ? 'Vous vous demandez si vous êtes éligible aux crédits de cours?' : 'Wondering if you are eligible for lecture credits?' }}
      <CmeEligibilityDialog />
    </h2>
  </div>
  <OAuth :ssoStateProp="ssoStateProp" />
  <div class="flex items-center w-full my-5 font-display">
    <div class="w-full line-divider" style="margin-left: 0"></div>
    <div class="mx-3 text-sm" id="registrationFormOrText">{{ selectedLanguage === 'Fr' ? 'Ou' : 'Or' }}</div>
    <div class="w-full line-divider" style="margin-left: 0"></div>
  </div>
  <form method="post" @submit.prevent="submit" class="grid grid-cols-2 gap-x-4">
    <div class="col-span-2 mb-3 text-start">
      <label for="email" class="text-sm font-display">Email Address</label>
      <InputText type="email" name="email" id="email" v-model="user.email" required class="w-full mt-1" maxLength="255" :disabled="isEmailDisabled" />
    </div>
    <div class="col-span-2 text-start" v-if="!loggedInUser">
      <label for="password" class="text-sm font-display">Password</label>
      <Password name="password" :inputId="'password'" v-model="user.password" :toggleMask="true" required :class="['w-full', { 'p-invalid': passwordError }]" maxLength="255">
        <template #footer>
          <p class="my-2 text-xs font-bold">Requirements</p>
          <ul class="text-xs p-pl-1 p-ml-1 registration-form-password-strength" style="line-height: 1.5">
            <li>
              <i :class="['pi', !/[a-z]/.test(user.password) ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i>
              {{ selectedLanguage === 'Fr' ? 'Au moins une minuscule' : 'At least one lowercase' }}
            </li>
            <li>
              <i :class="['pi', !/[A-Z]/.test(user.password) ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i>
              {{ selectedLanguage === 'Fr' ? 'Au moins une majuscule' : 'At least one uppercase' }}
            </li>
            <li>
              <i :class="['pi', !/[0-9]/.test(user.password) ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i>
              {{ selectedLanguage === 'Fr' ? 'Au moins un numéro' : 'At least one number' }}
            </li>
            <li>
              <i :class="['pi', !passwordRegex.test(user.password) ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i>
              {{ selectedLanguage === 'Fr' ? 'Au moins un caractère spécial' : 'At least one special character' }}
            </li>
            <li>
              <i :class="['pi', user.password.length < 10 ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i>
              {{ selectedLanguage === 'Fr' ? 'Minimum 10 caractères' : 'Minimum 10 characters' }}
            </li>
          </ul>
        </template>
      </Password>
    </div>
    <div class="col-start-1 col-end-3 text-start" v-if="!loggedInUser && passwordError">
      <span class="registration-error">
        {{ selectedLanguage === 'Fr' ? 'Les mots de passe ne répondent pas aux exigences suivantes:' : 'Passwords Do Not Meet the Following Requirements:' }}
        {{ passwordErrorMessage }}
      </span>
    </div>
    <div class="col-span-2 mt-8">
      <Button v-if="customButtonLabel" type="submit" id="submit" class="w-full text-center p-button-secondary" :label="customButtonLabel"></Button>
      <Button v-else type="submit" id="submit" class="w-full text-center p-button-secondary" :label="selectedLanguage === 'Fr' ? 'Rejoindre' : 'Join'"></Button>
    </div>
  </form>
  <div class="flex justify-center w-full mt-5">
    <label class="pl-1 text-xs">
      &nbsp;
      {{ selectedLanguage === 'Fr' ? "En m'inscrivant, j'accepte le Virtual Hallway" : 'By registering, I agree to the Virtual Hallway' }}
      <EulaDialog
    /></label>
  </div>

  <Dialog :visible="verifyEmailDialogVisible" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }" style="max-width: 470px" :draggable="false" :modal="true" id="verifyEmailDialog">
    <template #header>
      <span class="">Verify Email</span>
    </template>
    <div class="flex flex-col items-center justify-center w-full py-3">
      <div class="flex items-center justify-center w-24 h-24 mb-4 border-4 border-green-400 rounded-full">
        <i style="font-size: 2.5rem" class="text-green-400 pi pi-envelope"></i>
      </div>
      <div>{{ showVerifyEmailDialogMessage }}</div>
    </div>
    <template #footer>
      <Button
        id="verifyEmailDialogDoneBtn"
        class="p-button p-button-secondary"
        type="button"
        label="Done"
        @click="
          () => {
            $router.push({
              path: '/login',
              query: queryProps,
            });
          }
        "
      ></Button>
    </template>
  </Dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import Dropdown from 'primevue/dropdown';
import Password from 'primevue/password';
import Button from 'primevue/button';
import EulaDialog from '@/components/misc/EulaDialog.vue';
import OAuth from '@/views/public/OAuth.vue';

export default {
  components: {
    OAuth,
    InputText,
    InputMask,
    Dropdown,
    Password,
    Button,
    EulaDialog,
  },
  props: ['loggedInUser', 'background_role', 'lecture_id', 'customHeadline', 'customTagline', 'customButtonLabel', 'queryProps', 'prefillEmail', 'selectedLanguage'],
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        is_in_email_list: true,
        password: '',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        background_role: '',
        province: '',
        postal_code: '',
        profession: '',
        other_profession: '',
        invite_code: null,
        affiliate_id: '',
      },
      phoneNumberWithMask: '',
      error: '',
      phoneNumberError: false,
      phoneNumberErrorMsg: '',
      phoneCountryCodes: [{ name: '+1 (CAN/USA)', value: '1' }],
      selectedPhoneCountryCode: null,
      firstPhoneNumberChange: false,
      passwordError: false,
      postalCodeRegex: /([A-Za-z][0-9]){3}/,
      postalCodeError: false,
      provinceError: false,
      professionError: false,
      eulaAgreed: true,
      passwordRegex: /[\s~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()._]/,
      isEmailDisabled: false,
      allProfessions: ['Physician', 'Nurse Practitioner', 'Other Healthcare Profession', 'Non Healthcare Profession'],
      ssoStateProp: {},
      verifyEmailDialogVisible: false,
      showVerifyEmailDialogMessage: null,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isGP', 'isOrgUnitHead', 'isLoggedIn']),
  },
  methods: {
    ...mapActions(['register', 'login', 'registerUserForZoomLecture', 'createUserAnalytic']),
    submit() {
      if (this.validate()) {
        this.handleRegistration(this.user);
      }
    },
    validate() {
      let valid = true;
      this.validatePassword();
      if (this.passwordError) {
        valid = false;
      }
      return valid;
    },
    validatePassword() {
      this.passwordError = false;
      this.passwordErrorMessage = '';
      if (this.user.password.length < 10) {
        this.passwordErrorMessage += ' Minimum 10 characters';
        this.passwordError = true;
      }
      if (!/[a-z]/.test(this.user.password)) {
        this.passwordErrorMessage += ' One Lower Case Character';
        this.passwordError = true;
      }
      if (!/[A-Z]/.test(this.user.password)) {
        this.passwordErrorMessage += ' One Upper Case Character';
        this.passwordError = true;
      }
      if (!/[0-9]/.test(this.user.password)) {
        this.passwordErrorMessage += ' One Number';
        this.passwordError = true;
      }
      if (!this.passwordRegex.test(this.user.password)) {
        this.passwordErrorMessage += ' One Special Character (!,@,#,$,etc.)';
        this.passwordError = true;
      }
    },
    validateProfession() {
      if (this.user.profession == '') {
        this.professionError = true;
      } else {
        this.professionError = false;
      }
    },
    // validateProvince() {
    //   if (this.user.province == '') {
    //     this.provinceError = true;
    //   } else {
    //     this.provinceError = false;
    //   }
    // },
    validatePostalCode() {
      if (this.user.postal_code === null) {
        this.postalCodeError = true;
      } else if (this.user.province == 'United States') {
        this.postalCodeError = false;
      } else {
        this.user.postal_code = this.user.postal_code.replace(/\s+/g, '');
        if (this.user.postal_code.length === 5 || (this.user.postal_code.length === 6 && this.postalCodeRegex.test(this.user.postal_code))) {
          this.postalCodeError = false;
        } else {
          this.postalCodeError = true;
        }
      }
    },
    handleRegistration() {
      this.isLoading = true;
      this.user.isMobile = this.isNativePlatform();
      this.register(this.user)
        .then((res) => {
          if (this.customHeadline === 'poemsHeadline') {
            this.createUserAnalytic({
              analyticable_type: 'App\\Models\\Poem',
              user_id: res.data.user.id,
              description: 'User registered from poem page',
              tags: ['Poems'],
              meta_data: JSON.stringify({ url_query: this.$route.fullPath }),
            }).catch(() => {});
          }
          if (this.background_role === 'cme') {
            this.createUserAnalytic({
              analyticable_type: 'App\\Models\\Lecture',
              user_id: res.data.user.id,
              description: 'User registered from lecture page',
              tags: ['Lectures'],
              meta_data: JSON.stringify({ url_query: this.$route.fullPath }),
            }).catch(() => {});
          }
          if (this.user.invite_code) {
            this.login({ email: this.user.email, password: this.user.password, is_mobile: this.isNativePlatform() })
              .then((res) => {
                if (res.data.user.email_verified_at) {
                  this.isLoading = false;
                  this.handleSuccessfulLogin();
                } else {
                  this.isLoading = false;
                  this.$router.push({ path: '/home' });
                }
              })
              .catch(() => {
                this.isLoading = false;
                this.showGenericErrorToast();
              });
          } else {
            this.isLoading = false;
            this.showVerifyEmailDialog('Please check your email for a link to verify your account.');
          }
        })
        .catch((res) => {
          this.isLoading = false;
          if (res.status === 422) {
            if (res.data.errors.email && res.data.errors.email.includes('The email has already been taken.')) {
              this.login({ email: this.user.email, password: this.user.password, is_mobile: this.isNativePlatform() })
                .then((res) => {
                  if (res.data.user.email_verified_at) {
                    this.isLoading = false;
                    this.handleSuccessfulLogin();
                  }
                })
                .catch(() => {
                  this.isLoading = false;
                  this.$toast.add({
                    severity: 'error',
                    summary: 'Email already exists',
                    detail: 'That email already exists in our system. Please try to login.',
                    life: 10000,
                  });
                });
            } else if (res.data.errors.password && res.data.errors.password[0].includes('uncompromised')) {
              this.$toast.add({
                severity: 'error',
                summary: 'Please Choose Different Password',
                detail: 'It looks like that password is a weak password.',
                life: 10000,
              });
            } else {
              this.showGenericErrorToast();
            }
          } else {
            this.showGenericErrorToast();
          }
        });
    },
    handleZoomLectureRegistration() {
      this.registerUserForZoomLecture({
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        zip: this.user.postal_code,
        province: this.user.province,
        profession: this.user.profession,
        affiliate_id: this.user.affiliate_id,
        lecture_id: this.lecture_id,
      })
        .then((response) => {
          //For existing users who are registering for a lecture through the registration form while logged out
          if (this.isLoggedIn) {
            this.$toast.add({
              severity: 'success',
              summary: 'Lecture Registration Successful',
              detail: 'Please check your email for instructions on how to join the lecture.',
              life: 10000,
            });
          } else {
            this.showVerifyEmailDialog('Check your email for a link to verify your Virtual Hallway account, as well as instructions on how to watch the lecture.');
          }
        })
        .catch(() => {
          //TODO: handle when the backend returns a faulty value for the lecture
          this.showGenericErrorToast();
        });
    },
    handleSuccessfulLogin() {
      if (this.$route.query?.redirect) {
        this.$router.push(this.$route.query?.redirect);
      } else if (this.queryProps?.redirectFromRegistration) {
        this.$router.push(this.queryProps?.redirectFromRegistration);
      } else if (this.queryProps?.lecture_id) {
        this.$router.replace({ path: '/lecture/' + this.queryProps?.lecture_id, query: this.queryProps });
      } else if (this.isGP(this.loggedInUser)) {
        this.$router.push({ path: '/makereferral', query: this.queryProps });
      } else if (this.isOrgUnitHead(this.loggedInUser)) {
        this.$router.push({ path: '/manageusers', query: this.queryProps });
      } else {
        this.$router.push({ path: '/home', query: this.queryProps });
      }
    },
    showVerifyEmailDialog(message) {
      this.verifyEmailDialogVisible = true;
      this.showVerifyEmailDialogMessage = message;
    },
  },

  created() {
    if (this.prefillEmail) {
      this.user.email = this.prefillEmail;
    }
    if (this.queryProps) {
      this.ssoStateProp = { ...this.queryProps };
    }
    if (this.background_role == 'cme' || this.$route.query.br) {
      this.user.background_role = 'cme';
    }
    if (this.$route.query?.ref) {
      this.user.affiliate_id = this.$route.query?.ref;
    }
    if (this.$route.query.invited_email) {
      this.user.email = this.$route.query?.invited_email;
      this.user.first_name = this.$route.query?.invited_first_name;
      this.user.last_name = this.$route.query?.invited_last_name;
      this.phoneNumberWithMask = this.$route.query?.invited_phone_number;
      this.selectedPhoneCountryCode = { name: '+1 (CAN/USA)', value: '1' };
    }
    if (this.$route.query.accept_invite) {
      this.user.invite_code = this.$route.query.c;
      this.user.email = this.$route.query.email;
      this.isEmailDisabled = true;
      this.ssoStateProp.invite_code = this.user.invite_code;
    }
    this.selectedPhoneCountryCode = this.phoneCountryCodes[0];
  },
};
</script>

<style scoped>
.registration-error {
  color: #dc3545;
  font-size: 0.8rem;
  text-align: text-left;
}

.registration-form-password-strength i.pi-times-circle {
  color: var(--vh-error-red);
  padding-right: 2px;
}

.registration-form-password-strength i.pi-check-circle {
  color: var(--vh-success-green);
  padding-right: 2px;
}

.p-float-label label {
  margin-left: 0.5rem;
}

.p-input-icon-right > i {
  color: rgb(144, 144, 144) !important;
}
</style>
